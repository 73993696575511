import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { blue, green, pink } from "@material-ui/core/colors";
import HomeBanner from "../img/HOME_BANNER.png";
import BotReportStatus from "../homepage/BotReportStatus";
import { Box, Grid } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import LoginAlert from "./LoginAlert";
import HelpBox from "../homepage/HelpBox";
import { labels } from "../labels/labels.js";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  fixedHeight: {
    Height: "auto",
    minHeight: 350,
  },
  longHeight: {
    Height: "auto",
    minHeight: 750,
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  balanceCard: {
    height: "auto",
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
  blue: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
  },
  green: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: green[500],
  },
}));

function Home() {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const balancePaper = clsx(classes.paper, classes.balanceCard);
  const { isAuthenticated, isLoading } = useAuth0();

  //Loading Logic
  if (isLoading) {
    return (
      <div>
        <br /> {labels.loadingData}
      </div>
    );
  }

  //JSX (page)
  return isAuthenticated ? (
    <div>
      <Grid spacing={1} container xs={12} md={12}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper className={balancePaper}>
            <Box
              sx={{
                mt: 0,
                ml: -1,

                width: "830px",
                "& img": {
                  width: "100%",
                },
              }}
            >
              <img src={HomeBanner} />
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ mt: 2 }} xs={12}>
        {labels.showTutorials && (
          <Grid item xs={12} md={6} lg={6}>
            <Paper className={fixedHeightPaper}>
              <HelpBox />
            </Paper>
          </Grid>
        )}

        <br />
        <Grid item xs={12} md={6} lg={6}>
          <Paper className={fixedHeightPaper}>
            <BotReportStatus />
          </Paper>
        </Grid>
      </Grid>
    </div>
  ) : (
    <LoginAlert />
  );
}

export default Home;
