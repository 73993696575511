import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import CloudApiIntegration from "../components/integration/CloudApiIntegration";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";

import { cloudActivation, labels } from "../labels/labels.js";

import { Toast } from "primereact/toast";

const CloudApiActivation = (props) => {
  const emptyCloud = {
    id: null,
    botId: "",
    connectionName: "",
    token: "",
  };
  const [cloud, setCloud] = useState(emptyCloud);
  const [terms1, setTerms1] = useState(false);
  const [terms2, setTerms2] = useState(false);
  const botId = props.botId;
  const cloudApiIntegration = new CloudApiIntegration();

  const toast = useRef(null);

  useEffect(() => {
    if (botId) {
      cloudApiIntegration.getCloudApi(botId).then((data) => {
        if (data) {
          setCloud(data);
          if (data.connectionName != null) {
            setTerms1(true);
            setTerms2(true);
          }
        }
      });
    }
  }, []);
  const onInputChange = (e, name) => {
    const val = e.target.type === "checkbox" ? e.target.checked.toString() : e.target.value;
    let _event = { ...cloud };
    _event[`${name}`] = val;
    setCloud(_event);
  };

  const showResponseMessage = (json) => {
    if (json.id) {
      toast.current.show({
        severity: "success",
        summary: cloudActivation.success,
        detail: cloudActivation.successText,
        life: 5000,
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: cloudActivation.error,
        detail: cloudActivation.errorText,
        life: 5000,
      });
    }
  };

  const onCancel = () => {
    props.handleCloseCloudApi();
  };

  const onSubmit = () => {
    let templateCloud = {
      id: cloud.id,
      botId: botId,
      connectionName: cloud.connectionName,
      token: cloud.token,
    };
    cloudApiIntegration
      .postCloudApi(templateCloud)
      .then((response) => {
        showResponseMessage(response);
        setCloud(response);
        cloudApiIntegration
          .sendInteressingCloudEmail(response.botId, props.user, response.connectionName)
          .catch((error) => {
            toast.current.show({
              severity: "error",
              summary: cloudActivation.error,
              detail: cloudActivation.interestError,
              life: 5000,
            });
            console.log("error: ", error);
          });
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: cloudActivation.error,
          detail: cloudActivation.errorText,
          life: 5000,
        });
      });
  };

  return (
      <div>
        <Toast ref={toast} />
        <Typography variant="h4" component="h4" style={{ marginBottom: "20px" }}>
          Whatsapp Cloud API
        </Typography>
        <Grid container spacing={2} xs={12}>
          <Grid item xs={12} md={12}>
            <Checkbox checked={terms1} onChange={(e) => setTerms1(e.target.checked)} />
            <Typography variant="body2" style={{ display: "inline-block" }}>
              {cloudActivation.terms1}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Checkbox checked={terms2} onChange={(e) => setTerms2(e.target.checked)} />
            <Typography variant="body2" style={{ display: "inline-block" }}>
              {cloudActivation.terms2}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} marginTop="10px">
            <span className="p-float-label">
              <InputText
                id="connectionName"
                type="text"
                name="connectionName"
                value={cloud.connectionName}
                onChange={(e) => onInputChange(e, "connectionName")}
                style={{ width: "100%" }}
              />
              <label htmlFor="connectionName">Phone number ID</label>
            </span>
          </Grid>
          <Grid item xs={12} md={12} marginTop="10px">
            <span className="p-float-label">
              <InputTextarea
                rows={4}
                id="token"
                type="text"
                name="token"
                value={cloud.token}
                onChange={(e) => onInputChange(e, "token")}
                style={{ width: "100%" }}
                autoResize
              />
              <label htmlFor="token">Token</label>
            </span>
          </Grid>
          <Grid item xs={12} justifyContent="flex-end" display="flex">
            <Button
              type="submit"
              onClick={onSubmit}
              icon="pi pi-save"
              disabled={!terms1 || !terms2}
              label={cloudActivation.btnSave}
              className="p-button-text p-m-1"
            ></Button>
            <Button
              onClick={onCancel}
              icon="pi pi-times"
              label={cloudActivation.btnCancel}
              className="p-button-text p-m-1"
            ></Button>
          </Grid>
        </Grid>
      </div>
    )
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CloudApiActivation;
