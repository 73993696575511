import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ServicesIntegration from "../components/integration/ServicesIntegration";
import { EmployeesIntegration } from "../components/integration/EmployeesIntegration";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Slider } from "primereact/slider";
import { ToggleButton } from "primereact/togglebutton";
import { useAuth0 } from "@auth0/auth0-react";
import { SelectButton } from "primereact/selectbutton";

import { Link } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import "primeflex/primeflex.css";
import "../components/css/Services.css";
import LoginAlert from "./LoginAlert";

import { labels, myServices } from "../labels/labels.js";

const ServicesTable = () => {
  const [services, setServices] = useState([]);
  const [fullServices, setFullServices] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [servicesDialog, setServicesDialog] = useState(false);
  const [service, setService] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [reload, setReload] = useState(true);
  const [duration, setDuration] = useState(15);
  const toast = useRef(null);
  const dt = useRef(null);
  const servicesIntegration = new ServicesIntegration();
  const employeesIntegration = new EmployeesIntegration();
  const [loading, setLoading] = useState(true);

  const [mode, setMode] = useState(myServices.active);
  const options = [myServices.active, myServices.inactive];

  //login integration
  const { user, isAuthenticated, isLoading } = useAuth0();

  //Fetch Json Data
  const loadInitialData = async () => {
    const tenantId = user.email;
    if (fullServices.length === 0) {
      servicesIntegration.getServices(tenantId).then((data) => {
        setServices(
          data.filter((obj) => {
            return obj.enabled === (mode === myServices.active);
          })
        );
        setFullServices(data);
        setLoading(false);
      });

      employeesIntegration.getEmployees(tenantId).then((data) => {
        let localEmployees = [];
        if (data) {
          for (let i = 0, l = data.length; i < l; i += 1) {
            localEmployees[i] = {
              label: data[i].name,
              value: data[i].id,
            };
          }
          setEmployees(localEmployees);
          setReload(false);
        }
      });
    } else {
      setServices(
        fullServices.filter((obj) => {
          return obj.enabled === (mode === myServices.active);
        })
      );
      setLoading(false);
      setReload(false);
    }
  };

  let emptyService = {
    name: "",
    durationInMins: "15",
    meetingTitle: "",
    meetingDescription: "",
    location: "",
    virtual: true,
    enabled: true,
  };

  const openNew = () => {
    setService(emptyService);
    setSubmitted(false);
    setServicesDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setServicesDialog(false);
  };

  const showResponseMessage = (json) => {
    if (json.success) {
      toast.current.show({
        severity: "success",
        summary: "Sucesso!",
        detail: json.message,
        life: 5000,
      });
      //trigger reload data
      setReload(true);
      setFullServices([]);
    } else {
      toast.current.show({
        severity: "error",
        summary: "Erro!",
        detail: json.message,
        life: 5000,
      });
    }
    setLoading(false);
  };

  const saveService = () => {
    setServicesDialog(false);
    setLoading(true);
    setSubmitted(true);
    let templateService = {
      ...service,
      employees: selectedEmployees,
    };
    const tenantId = user.email;
    servicesIntegration
      .saveService(tenantId, service, templateService)
      .then((json) => {
        showResponseMessage(json);
      })
      .catch((error) => {
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: "Erro!",
          detail: myServices.errorEmployee,
          life: 5000,
        });
      });
  };

  const editService = (service) => {
    const tenantId = user.email;
    let serviceId = service.id;

    let selectedEmployees = [];

    setService({ ...service });
    setDuration(service.durationInMins);
    setServicesDialog(true);

    servicesIntegration.getServicesEmployee(tenantId, serviceId).then((serviceData) => {
      if (serviceData.service.employees) {
        serviceData.service.employees.map((employee) => {
          selectedEmployees.push(employee.id);
        });
        setSelectedEmployees(selectedEmployees);
      }
    });
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _service = { ...service };
    _service[`${name}`] = val;

    setService(_service);
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button label={myServices.btnNew} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
      </React.Fragment>
    );
  };
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <SelectButton
          value={mode}
          options={options}
          onChange={(e) => {
            setMode(e.value);
            setLoading(true);
          }}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => editService(rowData)}
        />
      </React.Fragment>
    );
  };
  const enabledTemplate = (rowData) => {
    return (
      <React.Fragment>
        <ToggleButton
          checked={rowData.enabled}
          onIcon="pi pi-check"
          offIcon="pi pi-times"
          onLabel={myServices.active}
          offLabel={myServices.inactive}
        />
      </React.Fragment>
    );
  };
  const sliderChange = (rowData) => {
    setDuration(rowData);
    //service updating duration
    let _service = { ...service };
    _service["durationInMins"] = rowData;
    setService(_service);
  };

  const header = (
    <div className="table-header">
      <h5 className="p-m-0">{myServices.searchService}</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={myServices.search} />
      </span>
    </div>
  );

  const serviceDialogFooter = (
    <React.Fragment>
      <Button label={myServices.btnCancel} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button label={myServices.btnSave} icon="pi pi-check" className="p-button-text" onClick={saveService} />
    </React.Fragment>
  );

  useEffect(() => {
    //loading logic
    if (isLoading) {
      return (
        <div>
          <br /> {labels.loadingData}
        </div>
      );
    } else if (isAuthenticated) {
      loadInitialData();
    }
  }, [isAuthenticated, reload, mode]);

  return isAuthenticated ? (
    <div className="page-container">
      <Toast ref={toast} />
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">
            <a href="#">{myServices.bookingType}</a>
          </li>
        </ol>
      </nav>

      <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

      <DataTable
        ref={dt}
        style={{ width: "70vw" }}
        value={services}
        dataKey="id"
        paginator
        rows={10}
        responsiveLayout="scroll"
        rowsPerPageOptions={[5, 10, 25]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate={myServices.currentPageReportTemplate}
        globalFilter={globalFilter}
        header={header}
        resizableColumns
        columnResizeMode="fit"
        emptyMessage={myServices.emptyTable}
        loading={loading}
      >
        <Column field="name" header={myServices.bookingType} sortable></Column>
        <Column field="durationInMins" header={myServices.duration} sortable></Column>
        <Column header={myServices.activeInactive} style={{ width: "15em" }} body={enabledTemplate}></Column>
        <Column body={actionBodyTemplate}></Column>
      </DataTable>

      <Dialog
        baseZIndex={1300}
        visible={servicesDialog}
        style={{ width: "800px", maxHeight: "95%" }}
        header={myServices.bookingType}
        modal
        className="p-fluid"
        footer={serviceDialogFooter}
        onHide={hideDialog}
      >
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12 p-md-12">
            <label htmlFor="name">{myServices.bookingType}</label>
            <InputText
              id="name"
              value={service.name}
              onChange={(e) => onInputChange(e, "name")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !service.name,
              })}
            />
          </div>
          <div className="p-field p-col-12 p-md-6">
            <label htmlFor="meetingTitle">{myServices.meetingTitle}</label>
            <InputText
              id="meetingTitle"
              value={service.meetingTitle}
              onChange={(e) => onInputChange(e, "meetingTitle")}
              required
            />
          </div>
          <div className="p-field p-col-12 p-md-6">
            <label htmlFor="meetingDescription">{myServices.meetingDescription}</label>
            <InputText
              id="meetingDescription"
              value={service.meetingDescription}
              onChange={(e) => onInputChange(e, "meetingDescription")}
              required
            />
          </div>
          <div className="p-field p-col-12 p-md-6">
            <label htmlFor="service">{myServices.qualifiedEmployees}</label>
            <MultiSelect
              value={selectedEmployees}
              options={employees}
              onChange={(e) => setSelectedEmployees(e.value)}
              placeholder={myServices.selectEmployee}
              display="chip"
            />
          </div>
          <div className="p-field p-col-12 p-md-6">
            <label htmlFor="duracaoInMin">{myServices.duration}</label>
            <InputText value={service.durationInMins} onChange={(e) => onInputChange(e, "durationInMins")} />
            <Slider value={duration} onChange={(e) => sliderChange(e.value)} step={5} max={120} min={15} />
          </div>
          <div className="p-field p-col-12">
            <label htmlFor="location">{myServices.localization}</label>
            <InputTextarea
              id="location"
              value={service.location}
              onChange={(e) => onInputChange(e, "location")}
              required
            />
          </div>
          <div className="p-field p-col-12 p-md-6">
            <h6 style={{ margin: "1px" }} htmlFor="virtual">
              {myServices.createLink}
            </h6>
            <InputSwitch id="virtual" checked={service.virtual} onChange={(e) => onInputChange(e, "virtual")} />
          </div>
          <div className="p-field p-col-12 p-md-6">
            <h6 style={{ margin: "1px" }}>
              {myServices.active}/{myServices.inactive}
            </h6>
            <InputSwitch id="enabled" checked={service.enabled} onChange={(e) => onInputChange(e, "enabled")} />
          </div>
        </div>
      </Dialog>
    </div>
  ) : (
    <LoginAlert />
  );
};
export default ServicesTable;
