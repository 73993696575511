import React from "react";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as MdIcons from "react-icons/md";
import { labels } from "../labels/labels.js";

const menuVehicle = [
  {
    title: "Home",
    path: "/",
    icon: <AiIcons.AiOutlineHome />,
    cName: "nav-text",
  },
  {
    title: "Minhas Revendas",
    path: "MyBots",
    icon: <AiIcons.AiOutlineRobot />,
    cName: "nav-text",
  },
  {
    title: "Campanhas",
    path: "Campaign",
    icon: <AiIcons.AiOutlineSound />,
    cName: "nav-text",
  },
  {
    title: "Ajuda",
    path: "help",
    icon: <IoIcons.IoMdHelpCircleOutline />,
    cName: "nav-text",
  },
];

const menuHealth = [
  {
    title: "Home",
    path: "/",
    icon: <AiIcons.AiOutlineHome />,
    cName: "nav-text",
  },
  {
    title: "Unidades de atendimento",
    path: "MyBots",
    icon: <AiIcons.AiOutlineRobot />,
    cName: "nav-text",
  },
  {
    title: "Cadastro Profissionais",
    path: "MyTeam",
    icon: <AiIcons.AiOutlineUser />,
    cName: "nav-text",
  },
  {
    title: "Tipos de Agendamento",
    path: "MyServices",
    icon: <MdIcons.MdMiscellaneousServices />,
    cName: "nav-text",
  },
  {
    title: "Calendario",
    path: "MyCalendar",
    icon: <AiIcons.AiOutlineCalendar />,
    cName: "nav-text",
  },
  {
    title: "Agendar consultas",
    path: "EventsCreation",
    icon: <AiIcons.AiOutlineBell />,
    cName: "nav-text",
  },
  {
    title: "Lembretes",
    path: "Reminders",
    icon: <AiIcons.AiOutlineCarryOut />,
    cName: "nav-text",
  },
  {
    title: "Campanhas",
    path: "Campaign",
    icon: <AiIcons.AiOutlineSound />,
    cName: "nav-text",
  },
  {
    title: "Ajuda",
    path: "help",
    icon: <IoIcons.IoMdHelpCircleOutline />,
    cName: "nav-text",
  },
];

const menuDefault = [
  {
    title: "Home",
    path: "/",
    icon: <AiIcons.AiOutlineHome />,
    cName: "nav-text",
  },
  {
    title: "Meus atendentes virtuais",
    path: "MyBots",
    icon: <AiIcons.AiOutlineRobot />,
    cName: "nav-text",
  },
  {
    title: "Cadastro de Profissionais",
    path: "MyTeam",
    icon: <AiIcons.AiOutlineUser />,
    cName: "nav-text",
  },
  {
    title: "Cadastro de Serviços",
    path: "MyServices",
    icon: <MdIcons.MdMiscellaneousServices />,
    cName: "nav-text",
  },
  {
    title: "Calendario",
    path: "MyCalendar",
    icon: <AiIcons.AiOutlineCalendar />,
    cName: "nav-text",
  },
  {
    title: "Agendamento manual",
    path: "EventsCreation",
    icon: <AiIcons.AiOutlineBell />,
    cName: "nav-text",
  },
  {
    title: "Lembretes de Agendamento",
    path: "Reminders",
    icon: <AiIcons.AiOutlineCarryOut />,
    cName: "nav-text",
  },
  {
    title: "Campanhas no Whatsapp",
    path: "Campaign",
    icon: <AiIcons.AiOutlineSound />,
    cName: "nav-text",
  },
  {
    title: "Ajuda da Plataforma",
    path: "help",
    icon: <IoIcons.IoMdHelpCircleOutline />,
    cName: "nav-text",
  },
];

const menuEnglish = [
  {
    title: "Home",
    path: "/",
    icon: <AiIcons.AiOutlineHome />,
    cName: "nav-text",
  },
  {
    title: "My Bots",
    path: "MyBots",
    icon: <AiIcons.AiOutlineRobot />,
    cName: "nav-text",
  },
  {
    title: "My Team",
    path: "MyTeam",
    icon: <AiIcons.AiOutlineUser />,
    cName: "nav-text",
  },
  {
    title: "My Services",
    path: "MyServices",
    icon: <MdIcons.MdMiscellaneousServices />,
    cName: "nav-text",
  },
  {
    title: "Calendar",
    path: "MyCalendar",
    icon: <AiIcons.AiOutlineCalendar />,
    cName: "nav-text",
  },
  {
    title: "Create Events",
    path: "EventsCreation",
    icon: <AiIcons.AiOutlineBell />,
    cName: "nav-text",
  },
  {
    title: "Booking Reminders",
    path: "Reminders",
    icon: <AiIcons.AiOutlineCarryOut />,
    cName: "nav-text",
  },
  {
    title: "Marketing Campaigns",
    path: "Campaign",
    icon: <AiIcons.AiOutlineSound />,
    cName: "nav-text",
  },
  {
    title: "Help",
    path: "help",
    icon: <IoIcons.IoMdHelpCircleOutline />,
    cName: "nav-text",
  },
];

export const SidebarData =
  labels.menuType === "vehicles"
    ? menuVehicle
    : labels.menuType === "menuHealth"
    ? menuHealth
    : labels.menuType === "menuEnglish"
    ? menuEnglish
    : menuDefault;
