export class EmployeesIntegration {

    getEmployees(tenantId) {
        return fetch(`${process.env.REACT_APP_BOT_API}/data/tenant/${tenantId}/employee`)
            .then(response => {
                if (response.status == 200) {
                    return response.json();
                } else if (response.status == 204) {
                    return [];
                }
                else {
                    return response.json()
                }
            })
            .catch(error => {
                console.log(error);
                window.alert('Erro ao obter lista de profissionais.');
            })
    }

    getEmployee(tenantId, employeeId) {
        return fetch(`${process.env.REACT_APP_BOT_API}/data/tenant/${tenantId}/employee/${employeeId}`)
            .then(response => response.json())
            .catch(error => {
                console.log(error);
                window.alert('Erro ao obter dados do profissional');
            })
    }

    getServices(tenantId) {
        return fetch(`${process.env.REACT_APP_BOT_API}/data/tenant/${tenantId}/service/`, {
            headers: {
                Accept: "application/json"
            },
        })
            .then(response => {
                if (response.status == 200) {
                    return response.json();
                } else if (response.status == 204) {
                    return [];
                }
                else {
                    return response.json()
                }
            })
            .catch(error => {
                console.log(error);
                window.alert('Erro ao obter lista de tipos de agendamento.');
            })
    }

    getRoles(tenantId) {
        return fetch(`${process.env.REACT_APP_BOT_API}/data/tenant/${tenantId}/employee/role`, {
            headers: {
                Accept: "application/json"
            },
        })
            .then(response => {
                if (response.status == 200) {
                    return response.json();
                } else if (response.status == 204) {
                    return [];
                }
                else {
                    return response.json()
                }
            })
            .catch(error => {
                console.log(error);
                window.alert('Erro ao obter lista de funções.');
            })
    }

    saveEmployee(tenantId, employee, templateEmployee) {
        //verify if it is editing
        if (employee.id) {
            return fetch(`${process.env.REACT_APP_BOT_API}/data/tenant/${tenantId}/employee/` + employee.id, {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(templateEmployee),
            })
                .then(response => response.json())
                .catch(error => {
                    throw error;
                });
        } else {
            //NEW EMPLOYEE
            return fetch(`${process.env.REACT_APP_BOT_API}/data/tenant/${tenantId}/employee`, {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(templateEmployee),
            })
                .then(response => response.json())
                .catch(error => {
                    throw error;
                });
        }
    }

}