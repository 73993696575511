export const searchTree = (treeData, key) => {
  for (let i = 0; i < treeData.length; i++) {
    let treeNode = treeData[i];
    if (treeNode.key == key) {
      return treeNode;
    } else if (treeNode.children != null) {
      let result = searchTree(treeNode.children, key);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

export const calculateNextKey = (parentNode) => {
  if(parentNode.children && parentNode.children.length > 0) {
	  const lastKey = parentNode.children[parentNode.children.length - 1];
    const splitted = lastKey.key.split('-');
    const finalKey = splitted[splitted.length - 1];
    const number = parseInt(finalKey) + 1;
    return parentNode.key + '-' + number;
  } else {
    return parentNode.key + '-0';
  }
}

export const getParentKey = (currentKey) => {
  const splitted = currentKey.split('-');
  let parentKey = "";
  for (let index = 0; index < splitted.length - 1; index++) {
    if(parentKey) {
      parentKey += "-";
    }
    parentKey += splitted[index];
  }
  return parentKey;
}

export const includeNewNode = (treeData, newNode) => {
  let parentKey = newNode.key;
  let parentNode = searchTree(treeData, parentKey);
  if (parentNode) {
    let newKey = calculateNextKey(parentNode);
    let newType = getNewNodeType(treeData, newNode);
    let childStructure;
    //console.log("type", newNode.type);
    if (newNode.type === "item") {
      //console.log("newType", newType);
      childStructure = {
        key: newKey,
        data: {
          name: newNode.name,
          type: newType,
          value: {
            name: "",
            code: "",
          },
          price: "",
        },
        type: newType,
      };
    } else {
      childStructure = {
        key: newKey,
        data: {
          name: newNode.name,
          type: newType,
        },
        type: newType,
        children: [],
      };
    }
    parentNode.children.push(childStructure);
  }
};

export const deleteNode = (treeData, nodeToRemove) => {
  let parentKey = nodeToRemove.key.substring(
    0,
    nodeToRemove.key.lastIndexOf("-")
  );
  let parentNode = searchTree(treeData, parentKey);
  if (parentNode && parentNode.children) {
    for (let i = 0; i < parentNode.children.length; i++) {
      let child = parentNode.children[i];
      if (child.key === nodeToRemove.key) {
        parentNode.children.splice(i, 1);
        return;
      }
    }
  }
};

function getNewNodeType(treeData, newNode) {
  if (newNode.type === "item") {
    // do something
    let endIndex =
      newNode.key.indexOf("-") > 0
        ? newNode.key.indexOf("-")
        : newNode.key.length;
    let rootKey = newNode.key.substring(0, endIndex);
    let rootNode = searchTree(treeData, rootKey);
    if (rootNode) {
      return rootNode.data.type;
    }
  }
  return newNode.type;
}
