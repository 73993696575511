export const ExcelColumns = [
    { name: 'A', code: '0' },
    { name: 'B', code: '1' },
    { name: 'C', code: '2' },
    { name: 'D', code: '3' },
    { name: 'E', code: '4' },
    { name: 'F', code: '5' },
    { name: 'G', code: '6' },
    { name: 'H', code: '7' },
    { name: 'I', code: '8' },
    { name: 'J', code: '9' },
    { name: 'K', code: '10' },
    { name: 'L', code: '11' },
    { name: 'M', code: '12' },
    { name: 'N', code: '13' },
    { name: 'O', code: '14' },
    { name: 'P', code: '15' },
    { name: 'Q', code: '16' },
    { name: 'R', code: '17' },
    { name: 'S', code: '18' },
    { name: 'T', code: '19' },
    { name: 'U', code: '20' },
    { name: 'V', code: '21' },
    { name: 'W', code: '22' },
    { name: 'X', code: '23' },
    { name: 'Y', code: '24' },
    { name: 'Z', code: '25' },
];
export const CHANNELS = ['WHATSAPP', 'INSTAGRAM'];
export const EMPTY_DROPDOWN_MESSAGE = 'Sem opções encontradas';
export const EMPTY_TABLE_MESSAGE = 'Nenhuma informação foi localizada';