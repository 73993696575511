export default class CampaignIntegration {
    saveCampaign(templateCampaign) {
        return fetch(process.env.REACT_APP_BOT_API + '/campaign/create', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(templateCampaign),
        })
            .then(response => response.json())
            .catch(error => {
                throw error;
            });

    }
    getCampaignMessages(botId) {
        return fetch(process.env.REACT_APP_BOT_API + '/campaign/message/' + botId)
            .then(response => response.json())
            .catch(error => {
                throw error;
            });
    }
    saveCampaignMessage(templateMessage, botId) {
        return fetch(process.env.REACT_APP_BOT_API + '/campaign/message/' + botId, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(templateMessage),
        })
            .then(response => response.json())
            .catch(error => {
                throw error;
            });

    }
    updateCampaignMessage(templateMessage, botId) {
        return fetch(process.env.REACT_APP_BOT_API + '/campaign/message/' + botId, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(templateMessage),
        })
            .then(response => response.json())
            .catch(error => {
                throw error;
            });

    }
    deleteMessage(message, botId) {
        return fetch(process.env.REACT_APP_BOT_API + '/campaign/message/' + botId, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json; charset=utf-8'
            },
            body: message,
        })
            .then(response => response.json())
            .catch(error => {
                throw error;
            });

    }
}