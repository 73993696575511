export default class CloudApiIntegration {

    getCloudApi(botId) {
        return fetch(process.env.REACT_APP_BOT_API + "/integration/whatsapp/cloudApi/" + botId).then(response => {
            return response.json();
        })
            .catch(error => {
                throw error;
            });
    }

    postCloudApi(template) {
        let json = JSON.stringify(template);
        return fetch(process.env.REACT_APP_BOT_API + "/integration/whatsapp/cloudApi/", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: json,
        })
            .then(response => response.json())
            .catch(error => {
                throw error;
            });
    }

    postChatGPT(botId, token, message) {
        return fetch(process.env.REACT_APP_BOT_API + "/integration/bot/" + botId + "/chatGPT/" + token, {
            method: "POST",
            headers: { 'Content-Type': 'text/plain' },
            body: message,
        })
            .then(response => response.json())
            .catch(error => {
                throw error;
            });
    }

    sendInteressingCloudEmail(botId, user, phone) {
        const cloudEmail = {
            contacts: ["meubot@lifty.com.br"],
            subject: "Cliente demonstrou interesse na CLOUD API",
            content: `Cliente: ${user.email}, quer a integação com CLOUD API e tentou usar o PHONE_NUMBER_ID: ${phone}`,
        };
        return fetch(`${process.env.REACT_APP_AGENDA_API_URL}/email/${botId}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(cloudEmail),
        })
            .then((response) => response.json())
            .catch((error) => {
                throw error;
            });
    }
}
