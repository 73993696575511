import React from "react";

function Calendar() {
  return (
    <div className="calendar">
      <h1>
        <iframe
          src="https://calendar.google.com/calendar/embed?src=c_cdb8enop8o9urbkcc86u6m8qk0%40group.calendar.google.com&ctz=America%2FSao_Paulo"
          width="1200"
          height="600"
          frameborder="0"
          scrolling="no"
        ></iframe>
      </h1>
    </div>
  );
}

export default Calendar;
