import React, { useState, useRef } from "react";
import RemindersIntegration from "../components/integration/RemindersIntegration";
import MybotsIntegrations from "../components/integration/MybotsIntegrations";

import { Link } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import useGlobalStyles from "../components/css/GlobalStyles";
import { Toast } from "primereact/toast";

import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Slider from "@mui/material/Slider";
import Input from "@mui/material/Input";

import { labels, reminders } from "../labels/labels.js";

import LoginAlert from "./LoginAlert";

function Reminders() {
  const emptyConfig = {
    id: null,
    confirmationMsg: "",
    advancedTime: 1,
    active: "true",
  };
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const classes = useGlobalStyles();

  const [botId, setBotId] = useState();
  const [myBots, setMyBots] = useState([]);

  const [config, setConfig] = useState(emptyConfig);

  const [timeSlider, setTimeSlider] = useState(1);
  const [selectedBot, setSelectedBot] = useState(false);

  const toast = useRef(null);

  const remindersIntegration = new RemindersIntegration();
  const mybotsIntegrations = new MybotsIntegrations();

  const [loading, setLoading] = useState(true);
  const [shouldLoadData, setShouldLoadData] = useState(true);

  const handleSliderChange = (event, newValue) => {
    setTimeSlider(newValue);
  };

  const handleInputChange = (event) => {
    setTimeSlider(event.target.value === "" ? "" : Number(event.target.value));
  };

  const onInputChange = (e, name) => {
    const val = e.target.type === "checkbox" ? e.target.checked.toString() : e.target.value;
    let _config = { ...config };
    _config[`${name}`] = val;
    setConfig(_config);
  };

  const handleBlur = () => {
    if (timeSlider < 1) {
      setTimeSlider(1);
    } else if (timeSlider > 48) {
      setTimeSlider(48);
    }
  };
  async function getAccessToken() {
    return await getAccessTokenSilently({
      audience: `https://lifty.us.auth0.com/api/v2/`,
      scope: "read:current_user",
    });
  }

  const loadInitialData = () => {
    getAccessToken().then((accessToken) => {
      const tenantId = user.email;
      if (shouldLoadData) {
        mybotsIntegrations.getBots(tenantId, accessToken).then((data) => {
          if (data) {
            let localvalue = [];
            for (let i = 0; i < data.length; i++) {
              localvalue[i] = {
                name: data[i].botName + " (" + data[i].botType + ")",
                code: data[i].botName,
                botId: data[i].id,
              };
            }
            setShouldLoadData(false);
            setMyBots(localvalue);
            setLoading(false);
          }
        });
      }
    });
  };

  const handleChangeBots = (e) => {
    setBotId(e.target.value.botId);

    remindersIntegration
      .getConfig(e.target.value.botId)
      .then((data) => {
        if (data.id) {
          let local = {
            id: data.id,
            confirmationMsg: data.confirmationMsg,
            advancedTime: data.advanceTime / 60,
            active: JSON.parse(data.active),
          };
          setConfig(local);
          setTimeSlider(data.advanceTime / 60);
        }
        setSelectedBot(e.target.value);
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: "Erro!",
          detail: "Erro ao Consultar Configurações.",
          life: 5000,
        });
      });
  };

  const showResponseMessage = (json) => {
    if (json.success) {
      toast.current.show({
        severity: "success",
        summary: reminders.success,
        detail: reminders.successText,
        life: 5000,
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: reminders.error,
        detail: reminders.errorText,
        life: 5000,
      });
    }
  };

  const onSubmit = () => {
    let templateConfig = {
      id: config.id,
      botId: botId,
      confirmationMsg: config.confirmationMsg,
      advanceTime: timeSlider * 60,
      active: config.active,
    };
    remindersIntegration
      .putConfig(templateConfig)
      .then((json) => {
        showResponseMessage(json);
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: "Erro!",
          detail: reminders.errorText,
          life: 5000,
        });
      });
  };

  //loading logic
  if (isLoading) {
    return (
      <div>
        <br /> {labels.loadingData}
      </div>
    );
  } else if (isAuthenticated) {
    //load bots after authentication
    loadInitialData();
  }
  return isAuthenticated ? (
    <div className="page-container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">
            <a href="#">{reminders.pageTitle}</a>
          </li>
        </ol>
      </nav>
      <Toast ref={toast} />
      <div>
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <Paper className={classes.paper} elevation={4}>
            <Typography component="h1" variant="h4">
              {reminders.pageTitle}
            </Typography>
            <div>
              <Dropdown
                style={{ marginTop: "20px", marginBottom: "20px", width: "590px" }}
                disabled={loading}
                value={selectedBot}
                options={myBots}
                onChange={(e) => handleChangeBots(e)}
                optionLabel="name"
                placeholder={reminders.chooseBot}
              />
            </div>
            {selectedBot ? (
              <div>
                <Grid container spacing={2} alignItems="center" xs={5}>
                  <Grid item xs>
                    <Box sx={{ width: 350 }}>
                      <Typography id="input-slider" gutterBottom>
                        {reminders.timeSlider}
                      </Typography>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs>
                          <Slider
                            step={1}
                            min={1}
                            max={48}
                            value={timeSlider}
                            onChange={handleSliderChange}
                            aria-labelledby="input-slider"
                          />
                        </Grid>
                        <Grid item>
                          <Input
                            sx={{ width: 42 }}
                            value={timeSlider}
                            size="small"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            inputProps={{
                              step: 1,
                              min: 1,
                              max: 48,
                              type: "number",
                              "aria-labelledby": "input-slider",
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      value={config.confirmationMsg}
                      onChange={(e) => onInputChange(e, "confirmationMsg")}
                      rows={4}
                      variant="outlined"
                      required
                      fullWidth
                      name="confirmationMsg"
                      label={reminders.reminderMsg}
                      id="confirmationMsg"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch checked={JSON.parse(config.active)} onChange={(e) => onInputChange(e, "active")} />
                      }
                      label={config.active ? reminders.active : reminders.inactive}
                    />
                  </Grid>
                  <Grid item xs={12} justifyContent="flex-end" display="flex">
                    <Button
                      type="submit"
                      onClick={onSubmit}
                      icon="pi pi-save"
                      label={reminders.btnSave}
                      className="p-button-raised p-button-success "
                    ></Button>
                  </Grid>
                </Grid>
              </div>
            ) : (
              ""
            )}
          </Paper>
        </Box>
      </div>
    </div>
  ) : (
    <LoginAlert />
  );
}
export default Reminders;
