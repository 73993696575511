import React from "react";
import { IconContext } from "react-icons";
import { Drawer as MUIDrawer, ListItem, List, ListItemIcon, ListItemText, Box, Divider } from "@material-ui/core";
import { SidebarData } from "../SidebarData";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { labels } from "../../labels/labels.js";

const DRAWER_WIDTH = 350;

const useStyles = makeStyles({
  drawer: {
    width: DRAWER_WIDTH,
  },
  paper: {
    backgroundColor: "#000c1e",
    color: "#FFFFFF",
    width: DRAWER_WIDTH,
  },
  divider: {
    color: "#FFFFFF",
    borderColor: "#ffffff",
    backgroundColor: "#2D3748",
    width: "300",
  },
});

//content

function DashboardMenu() {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <div>
      <MUIDrawer variant="permanent" className={classes.drawer} classes={{ paper: classes.paper }}>
        <Box
          sx={{
            display: "flex",
            ml: 5,
            mx: "auto",
            width: "280px",
            "& img": {
              width: "100%",
            },
          }}
        >
          <img src={labels.logo} alt="Lifty" />
        </Box>

        <List style={{ padding: "0" }}>
          <IconContext.Provider value={{ color: "white" }}>
            <List style={{ padding: "0" }}>
              {SidebarData.map((item, index) => {
                return (
                  <ListItem
                    key={index}
                    button
                    onClick={() => {
                      if (item.path !== "MyCalendar") {
                        navigate(item.path);
                      } else {
                        window.open("https://calendar.google.com/calendar/", "_blank");
                      }
                    }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.title} />
                  </ListItem>
                );
              })}
            </List>
          </IconContext.Provider>
        </List>
      </MUIDrawer>
    </div>
  );
}

export default DashboardMenu;
