import React, { useState, useEffect, useRef } from "react";
import { useLocation, Link } from "react-router-dom";
import ParamsIntegration from "../components/integration/ParamsIntegration";

// PRIMEREACT IMPORTS

import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { InputTextarea } from "primereact/inputtextarea";
import { SelectButton } from "primereact/selectbutton";
import { labels, configs } from "../labels/labels.js";
import { InputSwitch } from "primereact/inputswitch";

//Get BotId and BotName from URL Params
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

//Main Function
function BotParamsConfig() {
  const toast = useRef(null);
  const [paramsConfig, setParamsConfig] = useState([]);
  const [fullParamsConfig, setFullParamsConfig] = useState([]);

  const [mode, setMode] = useState(configs.basic);
  const options = [configs.basic, configs.advanced];

  const [loading, setLoading] = useState(true);

  const paramsIntegration = new ParamsIntegration();

  //Get BotId
  const query = useQuery();
  let botId = query.get("botId"); //Where BotId is the paramName on the URl.

  const reloadData = (botConfigData) => {
    const filteredConfig = botConfigData.parameters.filter((obj) => {
      return obj.modifiable && obj.advanced === (mode === configs.advanced);
    });
    setParamsConfig(filteredConfig);
    setFullParamsConfig(botConfigData);
    setLoading(false);
  }

  useEffect(() => {
    //Fetch Json Data
    if (fullParamsConfig.length === 0) {
      paramsIntegration
        .getBotConfiguration(botId)
        .then((resp) => {
          reloadData(resp);
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    } else {
      reloadData(fullParamsConfig);
    }
  }, [mode]);

  const saveParams = () => {
    setLoading(true);
    paramsIntegration
      .saveBotConfiguration(botId, fullParamsConfig)
      .then(
        toast.current.show({
          severity: "success",
          summary: configs.success,
          detail: configs.successText,
          life: 5000,
        })
      ).then(data => reloadData(data))
      .catch((error) => {
        console.log(error);
      });
  };
  const requiredValidator = (e) => {
    if (e.originalEvent && e.originalEvent.code === "Enter" && e.originalEvent.shiftKey) {
      return false;
    } else {
      return true;
    }
  };
  const handleChange = (options, e) => {
    let newParams = [...paramsConfig];
    let newRowData = options.rowData;
    newRowData.parameterValue = e.target.value;
    if (newRowData.parameterValue === true) {
      newRowData.parameterValue = "true";
    } else if (newRowData.parameterValue === false) {
      newRowData.parameterValue = "false";
    }
    newParams[options.rowIndex] = newRowData;
    setParamsConfig(newParams);
    fullParamsConfig.parameters.map((parameter) => {
      if (parameter.parameterName === newRowData.parameterName) {
        parameter.parameterValue = newRowData.parameterValue;
      }
    });
  };

  const editor = (options) => {
    if (options.field === "parameterValue") {
      if (
        options.rowData.parameterValue === "false" ||
        options.rowData.parameterValue === "true" ||
        options.rowData.parameterValue === false ||
        options.rowData.parameterValue === true
      ) {
        return booleanEditor(options);
      } else {
        return inputTextAreaEditor(options);
      }
    }
  };
  const inputTextAreaEditor = (options) => {
    return (
      <InputTextarea
        rows={5}
        autoResize
        value={options.rowData.value || options.rowData.parameterValue}
        onChange={(e) => handleChange(options, e)}
        onKeyDown={(e) => {
          if (e.code === "Enter") {
            if (e.shiftKey) {
              e.target.value = options.value + "\n";
              handleChange(options, e);
            }
            e.preventDefault();
          }
        }}
      />
    );
  };
  const booleanEditor = (options) => {
    return (
      <InputSwitch checked={options.rowData.parameterValue === "true"} onChange={(e) => handleChange(options, e)} />
    );
  };
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <SelectButton value={mode} options={options} onChange={(e) => setMode(e.value)} />
      </React.Fragment>
    );
  };
  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          type="submit"
          label={configs.btnSave}
          icon="pi pi-save"
          className="p-button-success p-mr-2"
          onClick={saveParams}
        />
      </React.Fragment>
    );
  };
  return (
    <div className="parameters">
      <Toast ref={toast} />
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/MyBots">{labels.breadCrumbs}</Link>
          </li>
          <li className="breadcrumb-item active">
            <a href="#">{configs.msgCrumb}</a>
          </li>
        </ol>
      </nav>

      <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

      <div className="p-fluid">
        <DataTable
          emptyMessage="Nenhum registro encontrado"
          style={{ width: "70vw" }}
          value={paramsConfig}
          editMode="cell"
          className="editable-cells-table"
          responsiveLayout="scroll"
          loading={loading}
        >
          <Column
            sortable
            cellEditValidator={requiredValidator}
            field="parameterName"
            header={configs.fieldName}
            style={{ width: "25%" }}
          />
          <Column
            sortable
            cellEditValidator={requiredValidator}
            field="parameterDescription"
            header={configs.fieldDescription}
            style={{ width: "25%" }}
          />
          <Column
            sortable
            cellEditValidator={requiredValidator}
            field="parameterValue"
            header={labels.parameterHeader}
            style={{ width: "25%" }}
            editor={(options) => editor(options)}
          />
        </DataTable>
      </div>
    </div>
  );
}

export default BotParamsConfig;
