import React, { useState } from "react";
import MybotsIntegrations from "../components/integration/MybotsIntegrations";
import { Link } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

import { Dropdown } from "primereact/dropdown";
import LoginAlert from "./LoginAlert";
import "../components/css/MyPlans.css";

function MyPlans() {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const [myBots, setMyBots] = useState([]);
  const [botType, setBotType] = useState();

  const [selectedBot, setSelectedBot] = useState(false);

  const mybotsIntegrations = new MybotsIntegrations();

  const [loading, setLoading] = useState(true);
  const [shouldLoadData, setShouldLoadData] = useState(true);
  async function getAccessToken() {
    return await getAccessTokenSilently({
      audience: `https://lifty.us.auth0.com/api/v2/`,
      scope: "read:current_user",
    });
  }

  const loadInitialData = () => {
    const tenantId = user.email;
    if (shouldLoadData) {
      getAccessToken().then((accessToken) => {
        mybotsIntegrations.getBots(tenantId, accessToken).then((data) => {
          if (data) {
            let localvalue = [];
            for (let i = 0; i < data.length; i++) {
              if (data[i]) {
                localvalue[i] = {
                  name: data[i].botName + " (" + data[i].botType + ")",
                  code: data[i].botName,
                  type: data[i].botType,
                };
              }
            }
            console.log(localvalue);
            setShouldLoadData(false);
            setMyBots(localvalue);
            setLoading(false);
          }
        });
      });
    }
  };
  const handleChangeBots = (e) => {
    setSelectedBot(e.target.value);
    setBotType(e.target.value.type);
  };

  //loading logic
  if (isLoading) {
    return (
      <div>
        <br /> &nbsp;Carregando dados ...
      </div>
    );
  } else if (isAuthenticated) {
    //load bots after authentication
    loadInitialData();
  }
  return isAuthenticated ? (
    <div className="page-container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">
            <a href="#">Planos</a>
          </li>
        </ol>
      </nav>
      <div class="dropdown panel ">
        <Dropdown
          style={{ minWidth: "300px" }}
          disabled={loading}
          value={selectedBot}
          options={myBots}
          onChange={(e) => handleChangeBots(e)}
          optionLabel="name"
          placeholder="Escolha seu bot"
          emptyMessage="Nenhum bot encontrado!"
        />
      </div>
      {selectedBot ? (
        <div class="container">
          <div class="panel pricing-table">
            <div class="pricing-plan">
              <img src="https://s22.postimg.cc/8mv5gn7w1/paper-plane.png" alt="" class="pricing-img" />
              <h2 class="pricing-header">Grátis</h2>
              <ul class="pricing-features">
                <li class="pricing-features-item">Funcionálidades Básicas</li>
                <li class="pricing-features-item">Apenas texto</li>
                <li class="pricing-features-item">Max. 1 cliente simultâneo</li>
                <li class="pricing-features-item">Max. 50 ações por mês</li>
                <li class="pricing-features-item">Sem SLA</li>
                <li class="pricing-features-item">Sucetível para perdas de dados</li>
                <li class="pricing-features-item">Perfeito para testar a plataforma e a aceitação dos clientes</li>
                <li class="pricing-features-item">Novas funcionalidades são lançadas primeiro nesse plano</li>
              </ul>
              <span class="pricing-price">R$ 0,00/mês</span>
              {botType === "GRATIS" ? (
                <button class="pricing-button is-adquired" disabled>
                  ATUAL
                </button>
              ) : (
                <a href="#/" class="pricing-button">
                  free trial
                </a>
              )}
            </div>

            <div class="pricing-plan">
              <img src="https://s28.postimg.cc/ju5bnc3x9/plane.png" alt="" class="pricing-img" />
              <h2 class="pricing-header">Light</h2>
              <ul class="pricing-features">
                <li class="pricing-features-item">Funcionalidades avançadas (Campanhas, etc)</li>
                <li class="pricing-features-item">Texto e Voz</li>
                <li class="pricing-features-item">Max. 5 clientes simultâneos</li>
                <li class="pricing-features-item">Max. 2000 ações por mês</li>
                <li class="pricing-features-item">SLA 90%</li>
                <li class="pricing-features-item">Sem perda de dados</li>
                <li class="pricing-features-item">Sem propagandas</li>
                <li class="pricing-features-item">Funcionalidades estáveis Respostas mais rápidas</li>
              </ul>
              <span class="pricing-price">R$ 129,90/mês</span>
              {botType === "LIGHT" ? (
                <button class="pricing-button is-adquired" disabled>
                  ATUAL
                </button>
              ) : (
                <a
                  href="https://www.mercadopago.com.br/subscriptions/checkout?preapproval_plan_id=2c93808481b537eb0181ca57521c097b"
                  class="pricing-button"
                  target="_blank"
                  rel="noreferrer"
                >
                  adiquirir
                </a>
              )}
            </div>

            <div class="pricing-plan">
              <img src="https://s21.postimg.cc/tpm0cge4n/space-ship.png" alt="" class="pricing-img" />
              <h2 class="pricing-header">Premium</h2>
              <ul class="pricing-features">
                <li class="pricing-features-item">Funcionalidade avançadas (Campanhas, etc)</li>
                <li class="pricing-features-item">Texto e Voz</li>
                <li class="pricing-features-item">Max. 10 clientes simultâneos</li>
                <li class="pricing-features-item">Max. 10000 ações por mês</li>
                <li class="pricing-features-item">SLA 95%</li>
                <li class="pricing-features-item">Sem perda de dados</li>
                <li class="pricing-features-item">Sem propagandas (Suporte para salvar dados de usuários)</li>
                <li class="pricing-features-item">Funcionalidades muito estáveis Respostas muito mais rápidas</li>
              </ul>
              <span class="pricing-price">R$ 249,90/mês</span>
              {botType === "PREMIUM" ? (
                <button class="pricing-button is-adquired" disabled>
                  ATUAL
                </button>
              ) : (
                <a
                  href="https://www.mercadopago.com.br/subscriptions/checkout?preapproval_plan_id=2c93808481c72f440181ca59b3ec017f"
                  class="pricing-button"
                  target="_blank"
                  rel="noreferrer"
                >
                  adiquirir
                </a>
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  ) : (
    <LoginAlert />
  );
}
export default MyPlans;
