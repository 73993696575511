import React, { useEffect, useState, useRef } from "react";

// ############## MATERIAL UI IMPORTS ######################

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import AndroidRoundedIcon from "@material-ui/icons/AndroidRounded";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";

// ############## PRIME REACT IMPORTS ######################

import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";

import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { SelectButton } from "primereact/selectbutton";

import { CreateBotIntegration } from "../components/integration/CreateBotIntegration";
import { useAuth0 } from "@auth0/auth0-react";
import LoginAlert from "./LoginAlert";
import { createBotLifty, labels } from "../labels/labels.js";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "#01b1c9",
    opacity: 0.8,
    padding: theme.spacing(2),
  },
  paper1: {
    backgroundColor: "#01b1c9",
    opacity: 0.8,
    padding: theme.spacing(2),
  },
}));

export default function CreateBot() {
  const toast = useRef(null);

  const createBotIntegration = new CreateBotIntegration();
  const classes = useStyles();

  const [templateOptions, setTemplateOptions] = useState([]);
  const [templates, setTemplates] = useState("");
  const [newBot, setNewBot] = useState("");
  const [botName, setBotName] = useState("");
  const [progress, setProgress] = useState(false);
  const [sucessDialog, setSucessDialog] = useState(false);
  const navigate = useNavigate();

  const [instanceId, setInstanceId] = useState("");

  const { user, isAuthenticated } = useAuth0();

  const doNothing = () => {};

  const showResponseMessage = (json) => {
    setNewBot(json);
    setBotName(json.botName);
    if (json.status && json.status !== 200) {
      toast.current.show({
        severity: "error",
        summary: createBotLifty.errorTitle,
        detail: json.error,
        life: 5000,
      });
    } else {
      toast.current.show({
        severity: "success",
        summary: createBotLifty.success,
        detail: createBotLifty.botSuccess,
        life: 5000,
      });
    }
  };

  useEffect(() => {
    createBotIntegration
      .getTemplates()
      .then((data) => {
        if (data) {
          setTemplateOptions(
            data.map((template) => ({
              name: template.nameTemplate,
              code: template.id,
            }))
          );
        } else {
          setTemplateOptions("");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: createBotLifty.errorTitle,
          detail: createBotLifty.errorMessage,
          life: 5000,
        });
      });
  }, []);
  const hideDialog = () => {
    setSucessDialog(false);
  };
  const configureBot = (botId) => {
    createBotIntegration
      .configureBot(botId)
      .then((json) => {
        toast.current.show({
          severity: "success",
          summary: createBotLifty.success,
          detail: createBotLifty.botCustomSuccess,
          life: 5000,
        });
        setProgress(false);
        setBotName("");
        setTemplates("");
      })
      .catch((error) => {
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: createBotLifty.errorTitle,
          detail: createBotLifty.errorMessage,
          life: 5000,
        });
        setProgress(false);
        setBotName("");
        setTemplates("");
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const tenant = user.email;
    // eslint-disable-next-line no-console
    let templateBot = {
      botName: data.get("botName"),
      templateId: templates.code,
      instanceId: instanceId,
      tenantId: tenant,
    };

    createBotIntegration
      .saveBot(templateBot)
      .then((json) => {
        showResponseMessage(json);
        if (json.id) {
          setNewBot(json);
          setBotName(json.botName);
          setProgress(true);
          setSucessDialog(true);
          configureBot(json.id);
        } else {
          toast.current.show({
            severity: "error",
            summary: createBotLifty.errorTitle,
            detail: createBotLifty.errorMessage,
            life: 5000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: createBotLifty.errorTitle,
          detail: createBotLifty.errorMessage,
          life: 5000,
        });
      });
  };

  const handleChangeTemplate = (event) => {
    setTemplates(event.target.value);
    let template = event.target.value.code;
    createBotIntegration
      .getInstance(template)
      .then((data) => {
        if (data) {
          let highestAvailableInstanceId;
          for (let i = 0; i < data.length; i++) {
            if (data[i].currentAvailableBotSlots > 0) {
              highestAvailableInstanceId = data[i].id;
              break;
            }
          }
          if (highestAvailableInstanceId) {
            setInstanceId(highestAvailableInstanceId);
          } else {
            window.alert(createBotLifty.botMaxAlert);
          }
        } else {
          setInstanceId("");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: createBotLifty.errorTitle,
          detail: createBotLifty.errorMessage,
          life: 5000,
        });
      });
  };

  return isAuthenticated ? (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/MyBots">{labels.breadCrumbs}</Link>
          </li>
          <li className="breadcrumb-item active">
            <a href="#">{createBotLifty.createBot}</a>
          </li>
        </ol>
      </nav>
      <Toast ref={toast} />
      <Paper className={classes.paper} elevation={4}>
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <Typography component="h1" variant="h4">
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <AndroidRoundedIcon />
            </Avatar>
            {createBotLifty.botCreation}
          </Typography>
          <Box required component="form" onSubmit={handleSubmit} sx={{ mt: 3, minWidth: "500px" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  name="botName"
                  required
                  fullWidth
                  onChange={(e) => setBotName(e.target.value)}
                  id="botName"
                  label={createBotLifty.nameBot}
                  autoFocus
                  value={botName}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component="h1" variant="h5" sx={{ mt: 3 }}>
                  {createBotLifty.chooseTemplate}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Paper className={classes.paper1} elevation={3}>
                  <SelectButton
                    optionLabel="name"
                    value={templates}
                    options={templateOptions}
                    onChange={handleChangeTemplate}
                    style={{ fontColor: "black" }}
                  ></SelectButton>
                </Paper>
              </Grid>
            </Grid>

            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2, width: 300 }}
              onClick={doNothing}
              disabled={!(instanceId && templates && botName)}
            >
              {createBotLifty.createBotNow}
            </Button>
          </Box>
        </Box>
      </Paper>
      <Dialog
        visible={sucessDialog}
        style={{ width: "800px" }}
        header={createBotLifty.botSuccess}
        modal
        className="p-d-flex"
        onHide={hideDialog}
        baseZIndex="1300"
      >
        {progress ? (
          <div className="p-grid">
            <div className="p-col-5 p-d-flex p-ai-center">
              <h5>{createBotLifty.waitingMsg}</h5>
            </div>
            <div className="p-col-3 p-d-flex p-ai-center">
              <ProgressSpinner />
            </div>
            <div className="p-col-4">
              <img width="280px" src="/BOTTY_NOBG.png" />
            </div>
          </div>
        ) : (
          <div className="p-grid">
            <div className="p-col-4">
              <img width="280px" src="/BOTTY_NOBG.png" />
            </div>
            <div className="p-col-8">
              <div className="p-grid">
                <div className="p-col-12">
                  <h5>{createBotLifty.botSuccess}</h5>
                </div>
                <div className="p-col-12">
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => window.open(process.env.REACT_APP_CHAT_URL + "/chat?botId=" + newBot.id, "_blank")}
                  >
                    {createBotLifty.testMsg}
                  </Button>
                </div>
                <div className="p-col-12">
                  <Button
                    variant="contained"
                    color="info"
                    onClick={() => navigate("/Integrations?botId=" + newBot.id + "&botName=" + newBot.botName)}
                  >
                    {createBotLifty.integrationMsg}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  ) : (
    <LoginAlert />
  );
}
