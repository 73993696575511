import React, { useState } from "react";
import EventsCreationIntegration from "../components/integration/EventsCreationIntegration.js";
import MybotsIntegrations from "../components/integration/MybotsIntegrations";
import { EmployeesIntegration } from "../components/integration/EmployeesIntegration";

import { Link } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { labels, eventsCreation } from "../labels/labels.js";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import LoginAlert from "./LoginAlert";

function EventsCreation() {
  const emptyEvent = {
    employeeId: "",
    desiredTime: new Date(),
    desiredDate: new Date(),
    customerEmail: "",
    customerId: "",
    channel: "WHATSAPP",
    nome: "",
    telefone: "",
    dataNascimento: new Date(),
    endereco: "",
    cartaoSUS: "",
  };
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const [botId, setBotId] = useState();
  const [myBots, setMyBots] = useState([]);
  const [selectedBot, setSelectedBot] = useState();
  const [emptyService, setEmptyService] = useState(false);
  const [services, setServices] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedService, setSelectedService] = useState();
  const [selectedEmployee, setSelectedEmployee] = useState();

  const [event, setEvent] = useState(emptyEvent);

  const eventsCreationIntegration = new EventsCreationIntegration();
  const mybotsIntegrations = new MybotsIntegrations();
  const employeesIntegration = new EmployeesIntegration();

  const [loading, setLoading] = useState(true);
  const [shouldLoadData, setShouldLoadData] = useState(true);

  const onInputChange = (e, name) => {
    const val = e.target.type === "checkbox" ? e.target.checked.toString() : e.target.value;
    let _event = { ...event };
    _event[`${name}`] = val;
    setEvent(_event);
  };
  async function getAccessToken() {
    return await getAccessTokenSilently({
      audience: `https://lifty.us.auth0.com/api/v2/`,
      scope: "read:current_user",
    });
  }

  const loadInitialData = () => {
    getAccessToken().then((accessToken) => {
      if (shouldLoadData) {
        const tenantId = user.email;
        mybotsIntegrations.getBots(tenantId, accessToken).then((data) => {
          if (data) {
            let localvalue = [];
            for (let i = 0; i < data.length; i++) {
              localvalue[i] = {
                name: data[i].botName + " (" + data[i].botType + ")",
                code: data[i].botName,
                botId: data[i].id,
              };
            }
            setMyBots(localvalue);
          }
        });
        employeesIntegration.getEmployees(tenantId).then((data) => {
          let localEmployees = [];
          if (data) {
            for (let i = 0, l = data.length; i < l; i += 1) {
              localEmployees[i] = {
                name: data[i].name,
                code: data[i].id,
              };
            }
            setEmployees(localEmployees);
            setLoading(false);
            setShouldLoadData(false);
          }
        });
      }
    });
  };
  const changeEmployee = (e) => {
    setSelectedEmployee(e.target.value);
    setEmptyService(false);
    const tenantId = user.email;
    let selectedServices = [];
    employeesIntegration.getEmployee(tenantId, e.target.value.code).then((employeeData) => {
      if (employeeData.services) {
        for (let i = 0, l = employeeData.services.length; i < l; i += 1) {
          selectedServices[i] = {
            name: employeeData.services[i].name,
            code: employeeData.services[i].id,
          };
        }
        setServices(selectedServices);
        setEmptyService(true);
      }
    });
  };

  const onSubmit = () => {
    let newDateOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const tenantId = user.email;
    let date = event.desiredDate;
    date = new Date(date.getTime());
    date = date.toISOString().split(".")[0] + "-00:00";
    let time = event.desiredTime;
    time = new Date(time.getTime());
    const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    const localISOTime = new Date(time - tzoffset).toISOString().slice(0, -1);
    console.log("ISOLocalTime", localISOTime);
    let templateEvent = {
      employeeId: selectedEmployee.code,
      desiredTime: localISOTime.split(".")[0] + "-00:00",
      customerEmail: event.customerEmail,
      customerId: event.telefone,
      channel: "WHATSAPP",
      parameters: {
        nome: event.nome,
        telefone: event.telefone,
        dataNascimento: event.dataNascimento.toLocaleString("pt-BR", newDateOptions),
        endereco: event.endereco,
        cartaoSUS: event.cartaoSUS,
      },
    };
    eventsCreationIntegration
      .createEvent(templateEvent, tenantId, botId, date, selectedService.code)
      .then((response) => {
        if (response.fulfillmentTextToUser === "INVALID_DATE") {
          window.alert(eventsCreation.invalidDate);
        } else if (response.fulfillmentEventName === "INVALID_DATE") {
          window.alert(eventsCreation.invalidDate);
        } else if (response.createdMeetingLink != null) {
          window.alert(eventsCreation.successMsg);
        } else {
          window.alert(eventsCreation.errorMsg);
          console.log("error: ", response);
        }
      })
      .catch((error) => {
        console.log(error);
        window.alert(JSON.stringify(error));
      });
  };

  //loading logic
  if (isLoading) {
    return (
      <div>
        <br /> {labels.loadingData}
      </div>
    );
  } else if (isAuthenticated) {
    //load bots after authentication
    loadInitialData();
  }
  return isAuthenticated ? (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">
            <a href="#">{labels.eventCreationBread}</a>
          </li>
        </ol>
      </nav>
      <div>
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <Paper elevation={4} style={{ margin: "1rem", padding: "1rem" }}>
            <Typography component="h1" variant="h4">
              {labels.eventCreationTitle}
            </Typography>
            <div>
              <Grid container spacing={2} xs={12}>
                <Grid item xs={12} marginTop="20px">
                  <span className="p-float-label">
                    <Dropdown
                      id="botId"
                      name="botId"
                      style={{ width: "100%" }}
                      disabled={loading}
                      value={selectedBot}
                      options={myBots}
                      onChange={(e) => {
                        setSelectedBot(e.target.value);
                        setBotId(e.target.value.botId);
                      }}
                      optionLabel="name"
                    />
                    <label htmlFor="botId">{labels.selectBot}</label>
                  </span>
                </Grid>
                <Grid item xs={6} marginTop="20px">
                  <span className="p-float-label">
                    <Dropdown
                      id="selectedEmployee"
                      value={selectedEmployee}
                      disabled={loading}
                      style={{ width: "100%" }}
                      options={employees}
                      onChange={(e) => changeEmployee(e)}
                      optionLabel="name"
                    />
                    <label htmlFor="selectedEmployee">{eventsCreation.selectStaff}</label>
                  </span>
                </Grid>
                <Grid item xs={6} marginTop="20px">
                  <span className="p-float-label">
                    {emptyService && <label htmlFor="selectedService">{eventsCreation.bookingType}</label>}
                    <Dropdown
                      value={selectedService}
                      disabled={!emptyService}
                      style={{ width: "100%" }}
                      options={services}
                      onChange={(e) => setSelectedService(e.target.value)}
                      optionLabel="name"
                      placeholder={!emptyService ? eventsCreation.selectStaffMsg : eventsCreation.bookingType}
                      aria-describedby="service"
                      className="block"
                    />
                  </span>
                </Grid>
                <Grid item xs={6} marginTop="20px">
                  <span className="p-float-label">
                    <Calendar
                      id="desiredDate"
                      style={{ display: "flex" }}
                      name="desiredDate"
                      value={event.desiredDate}
                      onChange={(e) => onInputChange(e, "desiredDate")}
                      dateFormat="dd/mm/yy"
                      mask="99/99/9999"
                      showIcon
                    />
                    <label htmlFor="desiredDate">{eventsCreation.bookingDate}</label>
                  </span>
                </Grid>
                <Grid item xs={6} marginTop="20px">
                  <span className="p-float-label">
                    <Calendar
                      id="desiredTime"
                      style={{ display: "flex" }}
                      value={event.desiredTime}
                      timeOnly
                      showTime
                      hourFormat="24"
                      onChange={(e) => onInputChange(e, "desiredTime")}
                      mask="99:99"
                      showIcon
                      name="desiredTime"
                      time
                    />
                    <label htmlFor="desiredTime">{eventsCreation.bookingTime}</label>
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <Typography component="h1" variant="h5" style={{ display: "flex", justifyContent: "center" }}>
                    {labels.eventCreationSubTitle}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} marginTop="20px">
                  <span className="p-float-label">
                    <InputText
                      id="nome"
                      name="nome"
                      value={event.nome}
                      onChange={(e) => onInputChange(e, "nome")}
                      style={{ width: "100%" }}
                    />
                    <label htmlFor="nome">{eventsCreation.fullName}</label>
                  </span>
                </Grid>
                {labels.showSus && (
                  <Grid item xs={12} md={6} marginTop="20px">
                    <span className="p-float-label">
                      <InputText
                        id="cartaoSUS"
                        type="number"
                        name="cartaoSUS"
                        value={event.cartaoSUS}
                        onChange={(e) => onInputChange(e, "cartaoSUS")}
                        style={{ width: "100%" }}
                      />
                      <label htmlFor="cartaoSUS">Nº Cartão do SUS</label>
                    </span>
                  </Grid>
                )}
                <Grid item xs={12} md={6} marginTop="20px">
                  <span className="p-float-label">
                    <Calendar
                      id="dateOfBirth"
                      style={{ display: "flex" }}
                      value={event.dataNascimento}
                      onChange={(e) => onInputChange(e, "dataNascimento")}
                      dateFormat="dd/mm/yy"
                      mask="99/99/9999"
                      showIcon
                      name="dateOfBirth"
                      placeholder=""
                    />
                    <label htmlFor="dateOfBirth">{eventsCreation.birthDate}</label>
                  </span>
                </Grid>
                <Grid item xs={12} md={6} marginTop="20px">
                  <span className="p-float-label">
                    <InputText
                      id="telefone"
                      type="number"
                      name="telefone"
                      value={event.telefone}
                      onChange={(e) => onInputChange(e, "telefone")}
                      style={{ width: "100%" }}
                    />
                    <label htmlFor="telefone">{eventsCreation.whatsappNumber}</label>
                  </span>
                </Grid>
                <Grid item xs={12} md={6} marginTop="20px">
                  <span className="p-float-label">
                    <InputText
                      id="customerEmail"
                      type="email"
                      name="customerEmail"
                      value={event.customerEmail}
                      onChange={(e) => onInputChange(e, "customerEmail")}
                      style={{ width: "100%" }}
                    />
                    <label htmlFor="customerEmail">E-Mail</label>
                  </span>
                </Grid>
                <Grid item xs={12} md={6} marginTop="20px">
                  <span className="p-float-label">
                    <InputText
                      id="endereco"
                      name="endereco"
                      value={event.endereco}
                      onChange={(e) => onInputChange(e, "endereco")}
                      style={{ width: "100%" }}
                    />
                    <label htmlFor="endereco">{eventsCreation.address}</label>
                  </span>
                </Grid>
                <Grid item xs={12} justifyContent="flex-end" display="flex">
                  <Button
                    type="submit"
                    disabled={!selectedBot || !selectedService}
                    onClick={onSubmit}
                    icon="pi pi-save"
                    label="Salvar"
                    className="p-button-raised p-button-success "
                  ></Button>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Box>
      </div>
    </div>
  ) : (
    <LoginAlert />
  );
}
export default EventsCreation;
