import React, { useState, useRef } from "react";
import UserProfileIntegration from "../components/integration/UserProfileIntegration";

import { Link } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Password } from "primereact/password";
import { Divider } from "primereact/divider";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import LoginAlert from "./LoginAlert";

import { labels, myProfile } from "../labels/labels.js";

import "../components/css/Profile.css";

function UserProfile() {
  const emptyProfile = {
    tenantId: "",
    businessName: "",
    documentNumber: "",
    address: "",
    district: "",
    city: "",
    state: "",
    zipCode: "",
  };

  const states = [
    { code: "AC", name: "Acre" },
    { code: "AL", name: "Alagoas" },
    { code: "AP", name: "Amapá" },
    { code: "AM", name: "Amazonas" },
    { code: "BA", name: "Bahia" },
    { code: "CE", name: "Ceará" },
    { code: "DF", name: "Distrito Federal" },
    { code: "ES", name: "Espírito Santo" },
    { code: "GO", name: "Goías" },
    { code: "MA", name: "Maranhão" },
    { code: "MT", name: "Mato Grosso" },
    { code: "MS", name: "Mato Grosso do Sul" },
    { code: "MG", name: "Minas Gerais" },
    { code: "PA", name: "Pará" },
    { code: "PB", name: "Paraíba" },
    { code: "PR", name: "Paraná" },
    { code: "PE", name: "Pernambuco" },
    { code: "PI", name: "Piauí" },
    { code: "RJ", name: "Rio de Janeiro" },
    { code: "RN", name: "Rio Grande do Norte" },
    { code: "RS", name: "Rio Grande do Sul" },
    { code: "RO", name: "Rondônia" },
    { code: "RR", name: "Roraíma" },
    { code: "SC", name: "Santa Catarina" },
    { code: "SP", name: "São Paulo" },
    { code: "SE", name: "Sergipe" },
    { code: "TO", name: "Tocantins" },
  ];
  const { user, isAuthenticated, isLoading } = useAuth0();

  const navigate = useNavigate();

  const [profile, setProfile] = useState(emptyProfile);
  const [state, setState] = useState([]);
  const [changePasswordDialog, setChangePasswordDialog] = useState();
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const userProfileIntegration = new UserProfileIntegration();

  const [shouldLoadData, setShouldLoadData] = useState(true);
  const toast = useRef(null);
  const lowerCaseLetters = RegExp(/[a-z]/g);
  const upperCaseLetters = RegExp(/[A-Z]/g);
  const numbers = RegExp(/[0-9]/g);

  const onInputChange = (e, name) => {
    const val = e.target.type === "checkbox" ? e.target.checked.toString() : e.target.value;
    let _event = { ...profile };
    _event[`${name}`] = val;
    setProfile(_event);
  };
  const stateChange = (e) => {
    setState(e.value);
  };

  const loadInitialData = () => {
    if (shouldLoadData) {
      let tenantId = user.email;
      userProfileIntegration.listUserProfile(tenantId).then((data) => {
        if (data) {
          setProfile(data);
          setState(data.state);
          setShouldLoadData(false);
        } else {
          setProfile(emptyProfile);
        }
      });
    }
  };
  const showResponseMessage = (json) => {
    if (json.success) {
      toast.current.show({
        severity: "success",
        summary: myProfile.success,
        detail: myProfile.successText,
        life: 5000,
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: myProfile.error,
        detail: myProfile.errorText,
        life: 5000,
      });
    }
  };
  const changePassword = () => {
    let tenantId = user.email;
    if (newPassword === confirmNewPassword) {
      let templatePassword = {
        password: newPassword,
        userId: user.sub,
      };
      userProfileIntegration
        .changePassword(tenantId, templatePassword)
        .then((response) => {
          if (response) {
            if (window.confirm(myProfile.passwordSuccess)) {
              navigate("/");
            } else navigate("#");
            setChangePasswordDialog(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: myProfile.error,
              detail: myProfile.passwordError,
              life: 5000,
            });
          }
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: myProfile.error,
            detail: myProfile.errorText,
            life: 5000,
          });
        });
    } else {
      toast.current.show({
        severity: "error",
        summary: myProfile.error,
        detail: myProfile.passwordMatchError,
        life: 5000,
      });
    }
  };
  const header = <h6 style={{ margin: "0", padding: "0" }}>{myProfile.passwordContent}:</h6>;
  const content = <h6 style={{ margin: "0", padding: "0" }}></h6>;
  const footer = (
    <React.Fragment>
      <Divider />
      <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: "1.5", listStyle: "none" }}>
        <li class={newPassword.length >= 8 ? "valid" : "invalid"}>{myProfile.lettersLength}</li>
        <li class={lowerCaseLetters.test(newPassword) ? "valid" : "invalid"}>{myProfile.lowercase}</li>
        <li class={upperCaseLetters.test(newPassword) ? "valid" : "invalid"}>{myProfile.uppercase}</li>
        <li class={numbers.test(newPassword) ? "valid" : "invalid"}>{myProfile.numbers}</li>
      </ul>
    </React.Fragment>
  );
  const hidePasswordDialog = () => {
    setChangePasswordDialog(false);
  };
  const showChangePasswordDialog = () => {
    setChangePasswordDialog(true);
  };
  const passwordDialogFooter = (
    <React.Fragment>
      <Button label={myProfile.btnCancel} icon="pi pi-times" className="p-button-text" onClick={hidePasswordDialog} />
      <Button
        label={myProfile.changePW}
        icon="pi pi-check"
        disabled={
          (!lowerCaseLetters.test(newPassword),
          !upperCaseLetters.test(newPassword),
          !numbers.test(newPassword),
          newPassword.length < 8)
        }
        className="p-button-text"
        onClick={changePassword}
      />
    </React.Fragment>
  );

  const onSubmit = () => {
    let templateProfile = {
      businessName: profile.businessName,
      documentNumber: profile.documentNumber,
      address: profile.address,
      district: profile.district,
      city: profile.city,
      state: state,
      zipCode: profile.zipCode,
    };
    userProfileIntegration
      .PutUserProfile(user.email, templateProfile)
      .then((response) => {
        showResponseMessage(response);
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: "Erro!",
          detail: myProfile.errorText,
          life: 5000,
        });
      });
  };

  //loading logic
  if (isLoading) {
    return (
      <div>
        <br /> {labels.loadingData}
      </div>
    );
  } else if (isAuthenticated) {
    //load bots after authentication
    loadInitialData();
  }
  return isAuthenticated ? (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">
            <a href="#">{myProfile.pageTitle}</a>
          </li>
        </ol>
      </nav>
      <Toast ref={toast} />
      <div>
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <Paper elevation={4} style={{ margin: "1rem", padding: "1rem" }}>
            <Typography component="h1" variant="h4">
              {myProfile.pageTitle}
            </Typography>
            <div>
              <Grid container spacing={2} xs={12}>
                <Grid item xs={12} md={12} marginTop="20px">
                  <span className="p-float-label">
                    <InputText
                      id="businessName"
                      type="text"
                      name="businessName"
                      value={profile.businessName}
                      onChange={(e) => onInputChange(e, "businessName")}
                      style={{ width: "100%" }}
                    />
                    <label htmlFor="businessName">{myProfile.businessName}</label>
                  </span>
                </Grid>
                <Grid item xs={12} md={6} marginTop="20px">
                  <span className="p-float-label">
                    <InputText
                      id="documentNumber"
                      type="text"
                      name="documentNumber"
                      value={profile.documentNumber}
                      onChange={(e) => onInputChange(e, "documentNumber")}
                      style={{ width: "100%" }}
                    />
                    <label htmlFor="documentNumber">{myProfile.documentNumber}</label>
                  </span>
                </Grid>
                <Grid item xs={12} md={6} marginTop="20px">
                  <span className="p-float-label">
                    <InputText
                      id="address"
                      name="address"
                      value={profile.address}
                      onChange={(e) => onInputChange(e, "address")}
                      style={{ width: "100%" }}
                    />
                    <label htmlFor="address">{myProfile.address}</label>
                  </span>
                </Grid>
                <Grid item xs={12} md={6} marginTop="20px">
                  <span className="p-float-label">
                    <InputText
                      id="district"
                      name="district"
                      value={profile.district}
                      onChange={(e) => onInputChange(e, "district")}
                      style={{ width: "100%" }}
                    />
                    <label htmlFor="district">{myProfile.district}</label>
                  </span>
                </Grid>
                <Grid item xs={12} md={6} marginTop="20px">
                  <span className="p-float-label">
                    <InputText
                      id="city"
                      name="city"
                      value={profile.city}
                      onChange={(e) => onInputChange(e, "city")}
                      style={{ width: "100%" }}
                    />
                    <label htmlFor="city">{myProfile.city}</label>
                  </span>
                </Grid>
                <Grid item xs={12} md={6} marginTop="20px">
                  <span className="p-float-label">
                    <Dropdown
                      value={state}
                      style={{ display: "flex" }}
                      options={states}
                      optionLabel="name"
                      onChange={stateChange}
                      required
                    />
                    <label htmlFor="state">{myProfile.state}</label>
                  </span>
                </Grid>
                <Grid item xs={12} md={6} marginTop="20px">
                  <span className="p-float-label">
                    <InputText
                      id="zipCode"
                      name="zipCode"
                      value={profile.zipCode}
                      onChange={(e) => onInputChange(e, "zipCode")}
                      style={{ width: "100%" }}
                    />
                    <label htmlFor="zipCode">{myProfile.zipCode}</label>
                  </span>
                </Grid>
                <Grid item xs={12} justifyContent="flex-end" display="flex">
                  <Button
                    type="submit"
                    onClick={onSubmit}
                    icon="pi pi-save"
                    label={myProfile.btnSave}
                    className="p-button-raised p-button-success p-m-1"
                  ></Button>
                  <Button
                    type="submit"
                    onClick={showChangePasswordDialog}
                    icon="pi pi-key"
                    label={myProfile.changePW}
                    className="p-button-raised p-button-warning  p-m-1"
                  ></Button>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Box>
        <Dialog
          visible={changePasswordDialog}
          style={{ width: "450px" }}
          header={myProfile.changePW}
          modal
          footer={passwordDialogFooter}
          onHide={hidePasswordDialog}
        >
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12">
              <label htmlFor="newPassword">{myProfile.newPasswordText}</label>
              <Password
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                toggleMask
                header={header}
                footer={footer}
                content={content}
              />
            </div>
            <div className="p-field p-col-12">
              <label htmlFor="confirmNewPassword">{myProfile.confirmNewPasswordText}</label>
              <Password
                type="password"
                id="confirmNewPassword"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                toggleMask
                feedback={false}
              />
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  ) : (
    <LoginAlert />
  );
}
export default UserProfile;
