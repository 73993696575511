import * as React from "react";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Screenshot_1 from "../img/Screenshot_1.png";
import Screenshot_2 from "../img/Screenshot_2.png";
import Screenshot_3 from "../img/Screenshot_3.png";
import Screenshot_4 from "../img/Screenshot_4.png";
import Screenshot_5 from "../img/Screenshot_5.png";
import Screenshot_6 from "../img/Screenshot_6.png";
import Screenshot_7 from "../img/Screenshot_7.png";
import Screenshot_8 from "../img/Screenshot_8.png";
import Screenshot_9 from "../img/Screenshot_9.png";
import Screenshot_10 from "../img/Screenshot_10.png";
import Screenshot_11 from "../img/Screenshot_11.png";
import Screenshot_12 from "../img/Screenshot_12.png";
import Screenshot_13 from "../img/Screenshot_13.png";
import Screenshot_14 from "../img/Screenshot_14.png";
import Screenshot_15 from "../img/Screenshot_15.png";
import Screenshot_16 from "../img/Screenshot_16.png";
import Screenshot_17 from "../img/Screenshot_17.png";
import Screenshot_18 from "../img/Screenshot_18.png";
import Screenshot_19 from "../img/Screenshot_19.png";
import Screenshot_20 from "../img/Screenshot_20.png";
import Screenshot_21 from "../img/Screenshot_21.png";

import useGlobalStyles from "../components/css/GlobalStyles";

import Paper from "@mui/material/Paper";

import "../components/css/help.css";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function Help() {
  const [expanded, setExpanded] = React.useState("");
  const classes = useGlobalStyles();
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div className="help-page">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">
            <a href="#">Ajuda</a>
          </li>
        </ol>
      </nav>
      <div>
        <Paper className={classes.paper1} elevation={3}>
          <Typography>
            <h5>Aqui você encontra os principais temas sobre a nossa plataforma e como criar e configurar seu chatbot em minutos.</h5>
            <h6>Tópicos:</h6>
          </Typography>

          <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <Typography>Primeiros passos</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <b>Criando o primeiro bot:</b>
              <p>
                Para criar seu primeiro bot, você deve ir até a pagina <b>'Meus Bots'</b>
                que fica a esquerda do seu vídeo.
              </p>
              <img src={Screenshot_1} alt="Screenshot_1" />
              <p>
                Nela serão listados todos os seus bots existentes, na tabela principal da tela. Acima da tabela na esquerda existe um botão azul, chamado <b>'CRIAR NOVO BOT'</b>, você deve clicar
                nele.
              </p>
              <img src={Screenshot_2} alt="Screenshot_2" />
              <p>
                Você será redirecionado para a tela de 'Criação de Bots', então para criar o seu novo bot, basta informar o nome do mesmo no campo <b>'Nome do Bot'</b>, e escolher um templete que mais
                se assemelha seu ramo de negócio. Após esses dois passos o botão abaixo <b>'CRIAR MEU BOT AGORA'</b> é liberado, sendo assim basta clicar nele.
              </p>
              <img src={Screenshot_3} alt="Screenshot_3" />
              <p>
                Agora resta apenas esperar a mensagem de sucesso aparecer e nosso primeiro bot está criado com sucesso! Abaixo também aparecerá um botão para você testar o seu bot, bastanto clicar
                nela para interagir com o mesmo. É importante lembrar, que a plataforma cria alguns cadastros de exemplo para seu bot funcionar, que são cadastro de funcionários de exemplo e cadastros
                de tipos de agendamentos, mensagens e menus. Você pode acessar esses cadastros e substituir os mesmos pelos valores que desejar. Para mais informações consulte a ajuda de{" "}
                <b>Configurações</b>.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
              <Typography>Integrações</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <b>Integrando seu bot ao Whatsapp:</b>
              <p>
                Para deixar seu bot acessível por outras plataformas de comunicação, basta acessar a página de 'Integrações', essa página fica no mesmo lugar que acabamos de acessar para criar nosso
                primeiro bot, portanto vá até <b>'Meus Bots'</b> no menu a esquerda, e a sua lista de bots deve aparecer na tabela, na linha onde localiza-se seu bot vai conter um botão azul chamado
                'Integrações', clique nele.
              </p>
              <img src={Screenshot_4} alt="Screenshot_4" />
              <p>
                Na página que aparece temos algumas opções de integrações com o nosso bot, sendo que atualmente temos disponibilidade apenas do Whatsapp. Para ativar a integração com seu Whatsapp você
                precisara de um Smartphone, que de preferencia não será usado para outros fins, apenas para uso do bot. Prosseguindo com a ativação podemos clicar no botão <b>'ATIVAR CONEXÃO'</b> que
                fica abaixo do ícone do Whatsapp, Abrirá uma caixa de dialogo com algumas instruções que devem ser seguidas para obtermos sucesso.
                <b>
                  Atenção: A integração com whatsapp não é oficial, e ainda que baixo, existem risco de restrições para seu número, especialmente se você o utilizar para disparo massivo de campanhas.
                </b>
              </p>
              <img src={Screenshot_5} alt="Screenshot_5" />
              <p>
                Após exibido o diálogo de conexão, clique em <b>'MOSTRAR QR CODE'</b>, sendo que ao final do texto aparecerá uma imagem QRCODE que deve ser lido utilizando o seu Whatsapp e a camêra de
                seu celular, ao ser lido o dialogo se fechará automaticamente mostrando então <b>'CONEXÂO ATIVADA'</b> abaixo do ícone do Whatsapp.
              </p>
              <img src={Screenshot_6} alt="Screenshot_6" />
              <p>
                Dessa forma seu <b>bot já está funcionando no seu whatsapp</b>, no número usado para leitura do QRCode, bastando enviar mensagens para esse número de outro número do whatsapp. Após
                testar a comunicação com seu bot via whatsapp, aproveite e para divulgar o número pra seus clientes.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
              <Typography>Configurações</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <b>Menus & Produtos:</b>
              <p>
                Dentro da pagina 'Meus Bots' na tabela onde se encontra o seu bot que foi criando anteriormente existe o botão <b>'MENUS & PRODUTOS'</b> o qual te leva a página de produtos.
              </p>
              <img src={Screenshot_7} alt="Screenshot_7" />
              <p>
                <Typography>
                  Na página de <b>Menu & Produtos</b> representa uma arvóre de produtos e serviços que seu bot vai oferecer. Aqui você pode configurar seus produtos, incluir links para os mesmos,
                  descrição, preços. Você pode também organizar seus serviços da maneira que for melhor para seu cliente, através de pastas. Ao abrir essa tela você vai encontrar 3 opções principais:
                </Typography>
                <ul>
                  <li>
                    <b>Meus Serviços:</b> A qual será utilizada para estruturar os serviços disponibilizados pela sua empresa. Lembre-se que cada item aqui deve ser atribuído (linkado) a um tipo de
                    agendamento, pois o objetivo principal do bot é fazer o agendamento de serviços de forma automática e inteligente.
                  </li>
                  <li>
                    <b>Meus Produtos:</b> Aqui voce poderá listar produtos comercializados por você e sua empresa. Caso você coloque um link de uma imagem, se a mesma estiver em formato <b>.jpg</b>, o
                    bot enviara a mesma via whatsapp. Lembrando de colocar um endereço de imagem público e acessível via internet. Também é possível colocar vídeos do Youtube, porém eles devem iniciar
                    com <b>https://www.youtube.com/watch</b>
                  </li>
                  <li>
                    <b>Duvidas:</b> Coloque aqui as dúvidas mais frequentes e as respostas, incluindo imagens ou vídeos para seus clientes.
                  </li>
                </ul>
              </p>
              <img src={Screenshot_8} alt="Screenshot_8" />
              <p>
                Para criar novos itens ou pastas, deve-se clicar no botão verde com símbolo <b>'+'</b>, no dialogo que surge deve-se informar o nome do item que for desejado ou também o nome de uma
                pasta para facilitar a sua organização.
              </p>
              <img src={Screenshot_9} alt="Screenshot_9" />
              <img src={Screenshot_10} alt="Screenshot_10" />
              <p>
                Posteriormente a criação pode-se adicionar mais informações ao que foi criado, como preço, link ou descrição, basta navegar até o item desejado e clicar no espaço em branco das colunas{" "}
                <b>'Link ou Descrição'</b> ou <b>'Preço'</b> que abrirá suas áreas para edição.
              </p>
              <img src={Screenshot_11} alt="Screenshot_11" />
              <img src={Screenshot_12} alt="Screenshot_12" />
              <p>
                E por ultimo mais não menos importante, precisamos salvar nossas alterações clicando no botão <b>'Salvar'</b> que fica no canto superior direito conforme imagem abaixo:
              </p>
              <img src={Screenshot_16} alt="Screenshot_16" />
              <p>
                <b>Configurando as mensagens do bot:</b>
              </p>
              <p>
                Para configurações das mensagens e demais parâmetros padrões do seu bot, deve-se acessar a pagina da mesma que fica na Listagem de bots no meu 'Meus Bots' clicando no botão{" "}
                <b>'MENSAGENS'</b> do bot escolhido.
              </p>
              <img src={Screenshot_13} alt="Screenshot_13" />
              <p>
                Nela encontramos 2 tipos de Parâmetros, os <b>BÁSICOS</b> e também os
                <b> AVANÇADOS</b>, podendo navegar entre eles para procurar o parâmetro que desejamos alterar. A coluna descrição informa exatamente o que cada parâmetro faz para facilitar sua
                alteração. e a coluna Valor é onde alteraremos conforme for necessário, bastando clicar no mesmo para iniciar sua edição.
              </p>
              <img src={Screenshot_14} alt="Screenshot_14" />
              <p>
                Após concluídas nossas alterações, não podemos esquecer de clicar no botão <b>salvar</b> no canto superior direito, para não perdemos tudo que foi alterado, esse passo é muito
                importante.
              </p>
              <img src={Screenshot_15} alt="Screenshot_15" />
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
              <Typography>Campanhas</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <b>Criar nova campanha:</b>
              <p>
                Para criar uma campanha devemos acessar a página <b>Campanhas</b> que se localiza no menu lateral esquerdo conforme foto abaixo.
              </p>
              <img src={Screenshot_17} alt="Screenshot_17" />
              <p>
                Para criarmos uma campanha precisaremos de uma planilha (XLS OU XLSX) com os dados dos clientes que você deseja fazer o envio. Logo abaixo temos um link para download de uma planilha
                de exemplo que deve ser seguida como modelo.
              </p>
              <img src={Screenshot_18} alt="Screenshot_18" />
              <p>A data e a hora já são colocados com valor atual ao carregar a página, podendo serem alteradas para fazer uma campanha agendada conforme necessidade.</p>
              <p>
                A linha abaixo contem o campo intervalo entre Leads, o qual determina o tempo de espera entre um envio para um cliente e o próximo (sendo importante para evitar bloqueios do seu
                whatsapp), deve-se selecionar também o bot que vai efetuar o disparo e a coluna que contém o número dos seus Leads na planilha que você enviou, por padrão é a coluna A.
              </p>
              <img src={Screenshot_19} alt="Screenshot_19" />
              <p>
                Logo abaixo temos a opção de anexar imagens a nossa campanha, é opcional, a cada imagem enviada uma nova tag #imagem
                <b>&#123;NOME-DA-IMAGEM&#125;</b> é criada e você pode manipular em qual parte do seu texto ela deve aparecer.
              </p>
              <img src={Screenshot_20} alt="Screenshot_20" />
              <p>Ao final qual tudo já estiver devidamente preenchido, podemos clicar no botão Criar campanha e nossa campanha será enviada.</p>
              <p>
                <b>Lembre-se, que para utilizar campanhas, é necessário ativar a integração com whatsapp antes.</b>
              </p>
              <img src={Screenshot_21} alt="Screenshot_21" />
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === "panel5"} onChange={handleChange("panel5")}>
            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
              <Typography>Youtube</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p>
                Abaixo encontra-se o link para nosso canal no <b>Youtube</b> que contém todos os videos com tutoriais de uso da nossa plataforma.
              </p>
              <a href="https://www.youtube.com/channel/UCyMLsP2NtlwFuWFPZg7d7rA/videos" target="_target">
                Youtube - MeuBot
              </a>
            </AccordionDetails>
          </Accordion>
        </Paper>
      </div>
    </div>
  );
}

export default Help;
