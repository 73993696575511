import React from "react";

function Reports() {
  return (
    <div className="reports">
      <h1>Reports</h1>
    </div>
  );
}

export default Reports;
