import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";

import React, { useState, useRef } from "react";
import { classNames } from "primereact/utils";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import ModelIntegration from "../components/integration/ModelIntegration";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { useLocation, Link } from "react-router-dom";
import { includeNewNode, deleteNode, searchTree } from "../components/js/NodeUtils";
import { useAuth0 } from "@auth0/auth0-react";
import { labels, models, myBots } from "../labels/labels.js";
import "../components/css/Models.css";
import LoginAlert from "./LoginAlert";

const DataTableCrudDemo = () => {
  //NOTE: This state is the main tree structure (JSON)
  const [nodes, setNodes] = useState([]);
  const [services, setServices] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [modelDialog, setModelDialog] = useState(false);
  const [deleteModelDialog, setDeleteModelDialog] = useState(false);
  //NOTE: This state is a new node to be added in the main structure
  const [model, setModel] = useState([]);
  const [reload, setReload] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loading, setLoading] = useState(true);
  const toast = useRef(null);
  const dt = useRef(null);

  const modelIntegration = new ModelIntegration();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const { user, isAuthenticated, isLoading } = useAuth0();

  const loadInitialData = () => {
    const botId = query.get("botId");
    const tenant = user.email;
    modelIntegration.getNodes(botId).then((data) => {
      setNodes(data);
      setLoading(false);
    });
    modelIntegration.getEmployees(tenant).then((data) => {
      let localEmployees = [];
      if (data) {
        for (let i = 0, l = data.length; i < l; i += 1) {
          let newEmployee = {
            name: data[i].name,
            code: data[i].id,
          };
          localEmployees.push(newEmployee);
        }
      }
      setEmployees(localEmployees);
    });
    modelIntegration.getServices(tenant).then((data) => {
      let localServices = [];
      if (data) {
        for (let i = 0, l = data.length; i < l; i += 1) {
          if (data[i].enabled) {
            let newService = {
              name: data[i].name,
              code: data[i].id,
            };
            localServices.push(newService);
          }
        }
      }
      setServices(localServices);
    });
  };

  const hideDialog = () => {
    setSubmitted(false);
    setModelDialog(false);
  };

  const hideDeleteModelDialog = () => {
    setDeleteModelDialog(false);
  };
  const showResponseMessage = () => {
    toast.current.show({
      severity: "success",
      summary: models.success,
      detail: models.infoAdded,
      life: 5000,
    });
  };

  const addNewModel = () => {
    setSubmitted(true);
    includeNewNode(nodes, model);

    setModelDialog(false);
  };
  const saveModel = async () => {
    let botId = query.get("botId");
    let bodySave = {
      root: [...nodes],
    };
    setSubmitted(true);
    //SAVE
    modelIntegration
      .saveModel(model, bodySave, botId)
      .then(() => {
        showResponseMessage();
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: models.errorTitle,
          detail: models.errorMsg,
          life: 5000,
        });
      });

    setModelDialog(false);
  };

  const newModel = async (model) => {
    setModel({ ...model });
    setModelDialog(true);
  };

  const confirmDeleteModel = (model) => {
    //console.log('Model to be deleted', model);
    setModel({ ...model });
    setDeleteModelDialog(true);
  };

  const deleteModel = async () => {
    deleteNode(nodes, model);
    setDeleteModelDialog(false);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _model = { ...model };
    _model[`${name}`] = val;
    //console.log("_model", _model);
    setModel(_model);
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button type="submit" label={models.save} icon="pi pi-save" className="p-button-success" onClick={saveModel} />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    if (rowData.key && rowData.key.indexOf("-") < 0) {
      return (
        <React.Fragment>
          <Button
            icon="pi pi-plus"
            className="p-button-rounded p-button-success p-mr-2"
            onClick={() => newModel(rowData)}
          />
        </React.Fragment>
      );
    } else if (rowData.data.type === "folder") {
      return (
        <React.Fragment>
          <Button
            icon="pi pi-plus"
            className="p-button-rounded p-button-success p-mr-2"
            onClick={() => newModel(rowData)}
          />
          <Button
            icon="pi pi-trash"
            className="p-button-rounded p-button-warning"
            onClick={() => confirmDeleteModel(rowData)}
          />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Button
            icon="pi pi-trash"
            className="p-button-rounded p-button-warning"
            onClick={() => confirmDeleteModel(rowData)}
          />
        </React.Fragment>
      );
    }
  };

  const header = (
    <div className="table-header">
      <h5 className="p-m-0">{models.menuAndProdSetting}</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={models.search} />
      </span>
    </div>
  );
  const returnDisabled = () => {
    if (model.name && model.type) {
      return false;
    } else {
      return true;
    }
  };
  const modelDialogFooter = (
    <React.Fragment>
      <Button label={models.btnCancel} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button
        disabled={returnDisabled()}
        label={models.btnSave}
        icon="pi pi-check"
        className="p-button-text"
        onClick={addNewModel}
      />
    </React.Fragment>
  );
  const deleteModelDialogFooter = (
    <React.Fragment>
      <Button label={models.btnNo} icon="pi pi-times" className="p-button-text" onClick={hideDeleteModelDialog} />
      <Button label={models.btnYes} icon="pi pi-check" className="p-button-text" onClick={deleteModel} />
    </React.Fragment>
  );

  const onEditorValueChange = (props, e, valueEditor, isCombo) => {
    let NewValue;
    if (valueEditor) {
      if (isCombo) {
        NewValue = e.value;
      } else {
        NewValue = {
          code: props.node.data.name,
          name: e.target.value
        };
      }
    } else {
      //simple text field
      NewValue = e.target.value;
    }
    // }
    let newNodes = JSON.parse(JSON.stringify(nodes));
    // console.log("Changing value to key", props.node.key);
    let editedNode = searchTree(newNodes, props.node.key);
    // console.log("Node found.", editedNode);
    if (editedNode) {
      // console.log('new nodes', newNodes);
      editedNode.data[props.field] = NewValue;
      setNodes(newNodes);
    }
  };

  const inputTextEditorValue = (options) => {
    return (
      <InputTextarea
        rows={5}
        placeholder={models.typeHere}
        style={{ maxWidth: "200px" }}
        type="text"
        value={options.value.name}
        onChange={(e) => onEditorValueChange(options, e, true, false)}
        onKeyDown={(e) => {
          if (e.code === "Enter") {
            if (e.shiftKey) {
              e.target.value = options.value.name + "\n";
              onEditorValueChange(options, e, true, false);
            }
            e.preventDefault();
          }
        }}
      />
    );
  };
  const inputServiceEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        emptyMessage={models.noBookingFound}
        onChange={(e) => onEditorValueChange(options, e, true, true)}
        optionLabel="name"
        options={services}
      />
    );
  };
  const inputEmployeeEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        emptyMessage={models.noStaffFound}
        onChange={(e) => onEditorValueChange(options, e, true, true)}
        optionLabel="name"
        options={employees}
      />
    );
  };
  const inputTextEditor = (options) => {
    return (
      <InputTextarea
        rows={2}
        placeholder={models.typeHere}
        style={{ maxWidth: "180px" }}
        type="text"
        value={options.value}
        onChange={(e) => onEditorValueChange(options, e, false, false)}
      />
    );
  };

  const folderEditor = (options) => {
    if (options.node.key && options.node.key.indexOf("-") >= 0) {
      return inputTextEditor(options);
    } else {
      return options.rowData.name;
    }
  };

  const linkEditor = (options) => {
    if (options.node.key && options.node.key.indexOf("-") >= 0) {
      switch (options.rowData.type) {
        case "service":
          return inputServiceEditor(options);
        case "employee":
          return inputEmployeeEditor(options);
        case "text":
          return inputTextEditorValue(options);
        case "folder":
          return "";
        default:
          return inputTextEditorValue(options);
      }
    } else {
      return "";
    }
  };

  const priceEditor = (options) => {
    if (options.node.key && options.node.key.indexOf("-") >= 0) {
      if (options.rowData.type === "folder") {
        return "";
      } else {
        return inputTextEditor(options);
      }
    } else {
      return "";
    }
  };

  const requiredValidator = (e) => {
    let props = e.columnProps;
    let value = props.node.data[props.field];

    return value && value.length > 0;
  };
  const valueBodyTemplate = (rowData) => {
    if (rowData.data.value) {
      return rowData.data.value.name;
    } else {
      return "";
    }
  };

  //loading logic
  if (isLoading) {
    return (
      <div>
        <br /> {labels.loadingData}
      </div>
    );
  } else if (isAuthenticated) {
    if (reload) {
      loadInitialData();
      setReload(false);
    }
  }

  return isAuthenticated ? (
    <div className="datatable-crud-demo">
      <Toast ref={toast} />

      <div className="card">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/MyBots">{labels.breadCrumbs}</Link>
            </li>
            <li className="breadcrumb-item active">
              <a href="#">{models.modelsCrumb}</a>
            </li>
          </ol>
        </nav>
        <Toolbar className="p-mb-4" right={rightToolbarTemplate}></Toolbar>

        <TreeTable
          ref={dt}
          style={{ width: "70vw" }}
          value={nodes}
          globalFilter={globalFilter}
          header={header}
          resizableColumns
          columnResizeMode="expand"
          emptyMessage={models.tableEmptyMsg}
          loading={loading}
        >
          <Column
            field="name"
            header={models.name}
            expander
            editor={folderEditor}
            cellEditValidator={requiredValidator}
            style={{ height: "3.5em", width: "300px" }}
          ></Column>
          <Column
            field="value"
            header={models.desc}
            body={valueBodyTemplate}
            editor={linkEditor}
            style={{ height: "3.5em" }}
          ></Column>
          <Column
            field="price"
            header={models.price}
            editor={priceEditor}
            cellEditValidator={requiredValidator}
            style={{ height: "3.5em" }}
          ></Column>
          <Column body={actionBodyTemplate}></Column>
        </TreeTable>
      </div>

      <Dialog
        visible={modelDialog}
        style={{ width: "450px" }}
        header={labels.newProductButton}
        modal
        className="p-fluid"
        footer={modelDialogFooter}
        onHide={hideDialog}
      >
        <div className="p-field">
          <label htmlFor="name">{models.name}</label>
          <InputText
            id="name"
            value={model.name}
            onChange={(e) => onInputChange(e, "name")}
            required
            autoFocus
            className={classNames({ "p-invalid": submitted && !model.name })}
          />
          {submitted && !model.name && <small className="p-error">{models.requiredNameField}</small>}
        </div>

        <div className="p-formgrid p-grid">
          <div className="p-field-radiobutton p-col-12 p-md-6">
            <RadioButton
              inputId="folder"
              value="folder"
              checked={model.type === "folder"}
              onChange={(e) => onInputChange(e, "type")}
            />
            <label htmlFor="folder">{labels.folder}</label>
          </div>
          <div className="p-field-radiobutton p-col-12 p-md-6">
            <RadioButton
              inputId="item"
              checked={model.type === "item"}
              value="item"
              onChange={(e) => onInputChange(e, "type")}
            />
            <label htmlFor="item">{labels.product}</label>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={deleteModelDialog}
        style={{ width: "450px" }}
        header={models.confirm}
        modal
        footer={deleteModelDialogFooter}
        onHide={hideDeleteModelDialog}
      >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
          {model && (
            <span>
              {models.deleteConfirmation} <b>{model.data ? model.data.name : ''}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </div>
  ) : (
    <LoginAlert />
  );
};
export default DataTableCrudDemo;
