import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Title from './Title';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ChevronRight from '@material-ui/icons/ChevronRight';
import SmsIcon from '@mui/icons-material/Sms';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { blue, green, pink } from '@material-ui/core/colors';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  depositContext: {
    flex: 1,
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
  blue: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
  },
  green: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: green[500],
  },
}));

export default function HelpBox() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Title>Tutoriais Importantes</Title>
      <Link to="/help" style={{ textDecoration: 'none', color: 'black' }}>
        <CardActionArea>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.green}>
                <SmsIcon />
              </Avatar>
            }
            action={
              <IconButton aria-label="settings">
                <ChevronRight />
              </IconButton>
            }
            title="Primeiros Passos"
            subheader="Veja como criar o seu primeiro Bot"
          />
        </CardActionArea>
      </Link>
      <Link to="/help" style={{ textDecoration: 'none', color: 'black' }}>
        <CardActionArea>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.blue}>
                <WhatsAppIcon />
              </Avatar>
            }
            action={
              <IconButton aria-label="settings">
                <ChevronRight />
              </IconButton>
            }
            title="Integrações"
            subheader="Como conectar seu bot ao WhatsApp e outros"
          />
        </CardActionArea>
      </Link>
      <Link to="/help" style={{ textDecoration: 'none', color: 'black' }}>
        <CardActionArea>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.pink}>
                <StorefrontIcon />
              </Avatar>
            }
            action={
              <IconButton aria-label="settings">
                <ChevronRight />
              </IconButton>
            }
            title="Configurações"
            subheader="Veja como configurar o seu bot"
          />
        </CardActionArea>
      </Link>
    </React.Fragment>
  );
}
