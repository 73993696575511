export default class UserProfileIntegration {

    listUserProfile(tenantId) {
        return fetch(process.env.REACT_APP_BOT_API + '/profile/' + tenantId).then(response => {
            return response.json();
        })
            .catch(error => {
                throw error;
            });
    }

    PutUserProfile(tenantId, templateUserProfile) {
        let json = JSON.stringify(templateUserProfile);
        return fetch(process.env.REACT_APP_BOT_API + '/profile/' + tenantId, {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: json,
        })
            .then(response => {
                if (response.status === 200) {
                    return {
                        success: true
                    };
                } else {
                    return {
                        success: false
                    };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    changePassword(tenantId, templatePassword) {
        let json = JSON.stringify(templatePassword);
        return fetch(`${process.env.REACT_APP_BOT_API}/profile/${tenantId}/changePassword`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: json,
        })
            .then(response => {
                return response
            })
            .catch(error => {
                throw error;
            });
    }
}
