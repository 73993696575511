import React, { useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import UserDataIntegration from "../components/integration/UserDataIntegration";
import { useAuth0 } from "@auth0/auth0-react";
import { CHANNELS, EMPTY_DROPDOWN_MESSAGE, EMPTY_TABLE_MESSAGE } from "../utils/constants";
import LoginAlert from "./LoginAlert";
import "../components/css/CreateBot.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";
import { Dropdown } from "primereact/dropdown";
import { labels, userDataLabels } from "../labels/labels.js";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const UserData = () => {
  let emptyData = {
    channel: "",
    userId: null,
    dataType: "",
    dataValue: "",
  };

  //states
  const [dataType, setDataType] = useState();
  const [shouldLoadData, setShouldLoadData] = useState(true);
  const [userData, setUserData] = useState(emptyData);
  const [usersData, setUsersData] = useState([]);
  const [userDataDialog, setUserDataDialog] = useState(false);
  const [deleteUserDataDialog, setDeleteUserDataDialog] = useState(false);
  const [deleteUsersDataDialog, setDeleteUsersDataDialog] = useState(false);
  const [selectedUsersData, setSelectedUsersData] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loading, setLoading] = useState(true);
  const toast = useRef(null);
  const dt = useRef(null);
  //auth0
  const { isAuthenticated, isLoading } = useAuth0();
  //local vars
  let botId;
  const query = useQuery();
  const userDataIntegration = new UserDataIntegration();

  //Fetch Json Data
  const loadInitialData = async () => {
    botId = query.get("botId");
    let templateId;

    if (shouldLoadData) {
      userDataIntegration.listUserData(botId).then((data) => {
        [templateId, botId] = [data.templateId, data.botId];
        if (data) {
          setShouldLoadData(false);
          if (data.userData) {
            setUsersData(data.userData);
          } else {
            setUsersData([]);
          }
        }
        userDataIntegration.listUserDataTypes(templateId).then((data) => {
          if (data.userDataTypes) {
            let newUserDataTypes = [];
            for (let i = 0, l = data.userDataTypes.length; i < l; i += 1) {
              newUserDataTypes[i] = data.userDataTypes[i].dataName;
            }
            setDataType(newUserDataTypes);
          }
        });
        setLoading(false);
      });
    }
  };

  const openNew = () => {
    setUserData(emptyData);
    setUserDataDialog(true);
  };

  const hideDialog = () => {
    setUserDataDialog(false);
  };
  const hideDeleteUserDataDialog = () => {
    setDeleteUserDataDialog(false);
  };

  const hideDeleteUsersDataDialog = () => {
    setDeleteUsersDataDialog(false);
  };
  const confirmDeleteUserData = (userData) => {
    setUserData(userData);
    setDeleteUserDataDialog(true);
  };
  const confirmDeleteSelected = () => {
    setDeleteUsersDataDialog(true);
  };

  const deleteUserData = () => {
    let _usersData = usersData.filter(
      (val) => userData.userId !== val.userId || userData.channel !== val.channel || userData.dataType !== val.dataType
    );
    setUsersData(_usersData);
    setDeleteUserDataDialog(false);
    setUserData(emptyData);
    toast.current.show({
      severity: "success",
      summary: userDataLabels.success,
      detail: userDataLabels.successDelText,
      life: 3000,
    });
  };
  const deleteSelectedUsersData = () => {
    let _usersData = usersData.filter((val) => !selectedUsersData.includes(val));
    setUsersData(_usersData);
    setDeleteUsersDataDialog(false);
    setSelectedUsersData(null);
    toast.current.show({
      severity: "success",
      summary: "Sucesso!",
      detail: userDataLabels.successDelText,
      life: 3000,
    });
  };
  const showResponseMessage = (json) => {
    if (json.success) {
      toast.current.show({
        severity: "success",
        summary: userDataLabels.success,
        detail: userDataLabels.successText,
        life: 5000,
      });
      setUserDataDialog(false);
    } else {
      toast.current.show({
        severity: "error",
        summary: userDataLabels.error,
        detail: userDataLabels.errorText,
        life: 5000,
      });
    }
  };

  const saveUserData = () => {
    let _usersData = [...usersData];
    let _userData = { ...userData };
    const index = findIndexById(userData.userId, userData.channel, userData.dataType);
    if (index >= 0) {
      _usersData[index] = _userData;
      toast.current.show({
        severity: "success",
        summary: userDataLabels.success,
        detail: userDataLabels.dataUpdate,
        life: 3000,
      });
    } else {
      _userData.userId = userData.userId;
      _usersData.push(_userData);
      toast.current.show({
        severity: "success",
        summary: userDataLabels.success,
        detail: userDataLabels.dataCreate,
        life: 3000,
      });
    }
    setUsersData(_usersData);
    setUserDataDialog(false);
    setUserData(emptyData);
  };
  const findIndexById = (id, channel, dataType) => {
    let index = -1;
    for (let i = 0; i < usersData.length; i++) {
      if (usersData[i].userId === id && usersData[i].channel === channel && usersData[i].dataType === dataType) {
        index = i;
        break;
      }
    }
    return index;
  };
  const sendUserData = () => {
    let templateUserData = { userData: [] };
    templateUserData.userData = [...usersData];
    userDataIntegration
      .PutUserData(botId, templateUserData)
      .then((json) => {
        showResponseMessage(json);
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: userDataLabels.error,
          detail: userDataLabels.errorText,
          life: 5000,
        });
      });
  };

  const editUserData = (userData) => {
    setUserData({ ...userData });
    setUserDataDialog(true);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _userData = { ...userData };
    _userData[`${name}`] = val;

    setUserData(_userData);
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button label="Novo" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
        <Button
          label={userDataLabels.btnDelete}
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedUsersData || !selectedUsersData.length}
        />
      </React.Fragment>
    );
  };
  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button label={userDataLabels.btnSave} icon="pi pi-save" className="p-button-success" onClick={sendUserData} />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => editUserData(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => confirmDeleteUserData(rowData)}
        />
      </React.Fragment>
    );
  };

  const header = (
    <div className="table-header">
      <h5 className="p-m-0">{userDataLabels.searchData}</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          dataType="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder={userDataLabels.search}
        />
      </span>
    </div>
  );
  const userDataDialogFooter = (
    <React.Fragment>
      <Button label={userDataLabels.btnCancel} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />

      <Button
        label={userDataLabels.btnSave}
        icon="pi pi-check"
        className="p-button-text"
        dataType="submit"
        onClick={saveUserData}
      />
    </React.Fragment>
  );
  const deleteUserDataDialogFooter = (
    <React.Fragment>
      <Button
        label={userDataLabels.btnNo}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteUserDataDialog}
      />
      <Button label={userDataLabels.btnYes} icon="pi pi-check" className="p-button-text" onClick={deleteUserData} />
    </React.Fragment>
  );
  const deleteUsersDataDialogFooter = (
    <React.Fragment>
      <Button
        label={userDataLabels.btnNo}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteUsersDataDialog}
      />
      <Button
        label={userDataLabels.btnYes}
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedUsersData}
      />
    </React.Fragment>
  );
  //loading logic
  if (isLoading) {
    return (
      <div>
        <br /> {labels.loadingData}
      </div>
    );
  } else if (isAuthenticated) {
    //load bots after authentication
    loadInitialData();
  }

  //JSX Page
  return isAuthenticated ? (
    <div className="page-container">
      <Toast ref={toast} />
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/MyBots">{labels.breadCrumbs}</Link>
          </li>
          <li className="breadcrumb-item active">
            <a href="#">{userDataLabels.pageTitle}</a>
          </li>
        </ol>
      </nav>

      <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

      <DataTable
        style={{ width: "70vw" }}
        ref={dt}
        emptyMessage={EMPTY_TABLE_MESSAGE}
        value={usersData}
        selection={selectedUsersData}
        onSelectionChange={(e) => setSelectedUsersData(e.value)}
        globalFilter={globalFilter}
        header={header}
        responsiveLayout="scroll"
        loading={loading}
      >
        <Column selectionMode="multiple" headerStyle={{ width: "3rem" }} exportable={false}></Column>
        <Column field="userId" header={userDataLabels.fieldId} sortable></Column>
        <Column field="channel" header={userDataLabels.channel} sortable></Column>
        <Column field="dataType" header={userDataLabels.dataType} sortable></Column>
        <Column field="dataValue" header={userDataLabels.data} sortable></Column>
        <Column body={actionBodyTemplate}></Column>
      </DataTable>

      <Dialog
        visible={userDataDialog}
        style={{ width: "30vw" }}
        header={userDataLabels.pageTitle}
        modal
        baseZIndex={1300}
        className="p-fluid"
        footer={userDataDialogFooter}
        onHide={hideDialog}
      >
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12">
            <label htmlFor="nickname">{userDataLabels.fieldId}</label>
            <InputText id="userId" value={userData.userId} onChange={(e) => onInputChange(e, "userId")} required />
          </div>
          <div className="p-field p-col-12">
            <label htmlFor="name">{userDataLabels.channel}</label>
            <Dropdown
              value={userData.channel}
              options={CHANNELS}
              emptyMessage={EMPTY_DROPDOWN_MESSAGE}
              onChange={(e) => onInputChange(e, "channel")}
              required
            />
          </div>
          <div className="p-field p-col-12">
            <label htmlFor="name">{userDataLabels.dataType}</label>
            <Dropdown
              value={userData.dataType}
              options={dataType}
              emptyMessage={EMPTY_DROPDOWN_MESSAGE}
              onChange={(e) => onInputChange(e, "dataType")}
              required
            />
          </div>
          <div className="p-field p-col-12">
            <label htmlFor="comercialNumber">{userDataLabels.data}</label>
            <InputText
              id="dataValue"
              value={userData.dataValue}
              onChange={(e) => onInputChange(e, "dataValue")}
              required
            />
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={deleteUserDataDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={deleteUserDataDialogFooter}
        onHide={hideDeleteUserDataDialog}
      >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {userData && (
            <span>
              {userDataLabels.confirmDelete} <b>{userData.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deleteUsersDataDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={deleteUsersDataDialogFooter}
        onHide={hideDeleteUsersDataDialog}
      >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {userData && <span>{userDataLabels.confirmDeleteSelected}</span>}
        </div>
      </Dialog>
    </div>
  ) : (
    <LoginAlert />
  );
};
export default UserData;
