
import React from 'react';
import { Card } from 'primereact/card';

const NoMobile = () => {

    return (
        <div>
            <Card title="Dispositivo não compatível" style={{ width: '25rem', marginBottom: '2em' }}>
                <p className="p-m-0" style={{ lineHeight: '1.5' }}>Por favor, acessar com um computador ou dispositivo com resolução mínima de 800X600px!</p>
            </Card>

        </div>
    )
}
export default NoMobile;