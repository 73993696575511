export default class BotReportIntegration {
  getBotStatus(tenantId) {
    return fetch(`${process.env.REACT_APP_BOT_API}/session/tenant/${tenantId}/stats`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json; charset=utf-8",
      },
    })
      .then((res) => res.json())
      .then((d) => d)
      .catch((error) => {
        console.log(error);
        window.alert("Erro ao obter status do bot.");
      });
  }
}
