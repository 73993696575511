import React, { useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import MybotsIntegrations from "../components/integration/MybotsIntegrations";
import { useAuth0 } from "@auth0/auth0-react";
import LoginAlert from "./LoginAlert";
import "../components/css/CreateBot.css";
import { labels, myBots } from "../labels/labels.js";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";

//MainComponent
const MyBots = () => {
  const [tableData, setTableData] = useState([]);
  const [shouldLoadData, setShouldLoadData] = useState(true);
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const mybotsIntegrations = new MybotsIntegrations();
  const toast = useRef(null);

  const [chatUrl, setChatUrl] = useState("");
  const [botName, setBotName] = useState("");
  const [testDialog, setTestDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [rowBot, setRowBot] = useState(null);

  const navigate = useNavigate();

  const [expandedRows, setExpandedRows] = useState(null);

  async function getAccessToken() {
    return await getAccessTokenSilently({
      audience: `https://lifty.us.auth0.com/api/v2/`,
      scope: "read:current_user",
    });
  }

  //Fetch Json Data
  const loadInitialData = async () => {
    const tenantId = user.email;
    if (shouldLoadData) {
      getAccessToken().then((accessToken) => {
        mybotsIntegrations.getBots(tenantId, accessToken).then((data) => {
          if (data) {
            setShouldLoadData(false);
            setTableData(data);
            setLoading(false);
          }
        });
      });
    }
  };
  const handleChat = (data) => {
    const chatURL = process.env.REACT_APP_CHAT_URL + "/chat?botId=" + data.id;
    setChatUrl(chatURL);
    setTestDialog(true);
    setBotName(data.botName);
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="orders-subtable">
        <div className="p-fluid p-grid">
          <div className="p-col">
            <Button
              style={{ height: "100%" }}
              icon="pi pi-tags"
              label={myBots.menuAndProducs}
              className="p-button-rounded p-button-primary p-mr-2"
              onClick={() => navigate("/Models?botId=" + data.id)}
            />
          </div>
          <div className="p-col">
            <Button
              style={{ height: "100%" }}
              icon="pi pi-cog"
              label={myBots.config}
              className="p-button-rounded p-button-primary p-mr-2"
              onClick={() => navigate("/BotParamsConfig?botId=" + data.id)}
            />
          </div>
          {/* <div className="p-col">
            <Button
              style={{ height: "100%" }}
              icon="pi pi-database"
              label="DADOS DE USUÁRIOS"
              className="p-button-rounded p-button-primary p-mr-2"
              onClick={() => navigate("/UserData?botId=" + data.id)}
            />
          </div> */}
          <div className="p-col">
            <Button
              style={{ height: "100%" }}
              icon="pi pi-whatsapp"
              label={myBots.connections}
              className="p-button-rounded p-button-primary p-mr-2"
              onClick={() => navigate("/Integrations?botId=" + data.id + "&botName=" + data.botName)}
            />
          </div>
          <div className="p-col">
            <Button
              style={{ height: "100%" }}
              icon="pi pi-caret-right"
              label={myBots.tryIt}
              className="p-button-rounded p-button-primary p-mr-2"
              onClick={() => handleChat(data)}
            />
          </div>
        </div>
      </div>
    );
  };
  const confirmDeleteBot = (rowData) => {
    setRowBot(rowData);
    setDeleteDialog(true);
  };
  const deleteBot = () => {
    mybotsIntegrations
      .deleteBot(rowBot.id)
      .then((json) => {
        toast.current.show({
          severity: "success",
          summary: myBots.sucess,
          detail: myBots.deleteSuccess,
          life: 3000,
        });
        setDeleteDialog(false);
        setLoading(true);
        setShouldLoadData(true);
        loadInitialData();
      })
      .catch((error) => {
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: "Erro!",
          detail: myBots.deleteError,
          life: 5000,
        });
      });
  };
  const leftToolbarTemplate = () => {
    if (user.email && user.email.indexOf("@lifty.com.br") > 0) {
      return (
        <React.Fragment>
          <Button
            icon="pi pi-plus"
            label={myBots.createNew}
            className="p-button-lg p-button-success p-mr-2"
            onClick={() => navigate("/CreateBot")}
          />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Button
            icon="pi pi-plus"
            label={myBots.createNew}
            className="p-button-lg p-button-success p-mr-2"
            onClick={() => navigate("/Businessline")}
          />
        </React.Fragment>
      );
    }
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger p-mr-2"
          onClick={() => confirmDeleteBot(rowData)}
        />
      </React.Fragment>
    );
  };
  const hidedeleteDialog = () => {
    setDeleteDialog(false);
  };
  const deleteDialogFooter = (
    <React.Fragment>
      <Button label={myBots.btnNo} icon="pi pi-times" className="p-button-text" onClick={hidedeleteDialog} />
      <Button label={myBots.btnYes} icon="pi pi-check" className="p-button-text" onClick={deleteBot} />
    </React.Fragment>
  );

  //loading logic
  if (isLoading) {
    return (
      <div>
        <br /> {myBots.loading}
      </div>
    );
  } else if (isAuthenticated) {
    loadInitialData();
  }

  //JSX (page)
  return isAuthenticated ? (
    <div className="page-container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">
            <a href="#">{labels.breadCrumbs}</a>
          </li>
        </ol>
      </nav>
      <div>
        <div className="datatable-rowexpansion-demo">
          <Toast ref={toast} />
          {labels.createNew && <Toolbar className="p-mb-4" left={leftToolbarTemplate}></Toolbar>}
          <div className="card">
            <DataTable
              value={tableData}
              expandedRows={expandedRows}
              onRowToggle={(e) => setExpandedRows(e.data)}
              responsiveLayout="scroll"
              rowExpansionTemplate={rowExpansionTemplate}
              dataKey="id"
              style={{ width: "70vw" }}
              loading={loading}
              emptyMessage={myBots.emptyMsg}>
              <Column expander style={{ width: "3em" }} />
              <Column field="botName" header={labels.botName} sortable />
              <Column field="botType" header={labels.botType} sortable />
              <Column field="templateName" header="Template" sortable />
              <Column body={actionBodyTemplate}></Column>
            </DataTable>
          </div>
        </div>
      </div>
      <Dialog
        visible={testDialog}
        position={"bottom-right"}
        modal={false}
        style={{ height: "100%", width: "600px" }}
        contentStyle={{ padding: "0px", overflow: "hidden" }}
        header={botName}
        baseZIndex={1300}
        onHide={() => setTestDialog(false)}>
        <iframe
          title="myBook"
          src={chatUrl}
          frameBorder="0"
          scrolling="no"
          allowFullScreen={true}
          style={{
            width: "100%",
            height: "100%",
            padding: "0",
            float: "none",
            overflowY: "none",
          }}
        ></iframe>
      </Dialog>
      <Dialog
        visible={deleteDialog}
        style={{ width: "450px" }}
        header={myBots.confirm}
        modal
        footer={deleteDialogFooter}
        onHide={hidedeleteDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
          {rowBot && (
            <span>
              {myBots.deleteConfirmation} <b>{rowBot.botName}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </div>
  ) : (
    <LoginAlert />
  );
};
export default MyBots;