import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { EmployeesIntegration } from "../components/integration/EmployeesIntegration";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputMask } from "primereact/inputmask";
import { ToggleButton } from "primereact/togglebutton";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { InputSwitch } from "primereact/inputswitch";
import { useAuth0 } from "@auth0/auth0-react";
import { SelectButton } from "primereact/selectbutton";
import "../components/css/Employees.css";
import { Link } from "react-router-dom";

import LoginAlert from "./LoginAlert";
import WeekTime from "../components/WeekTime";
import { myTeam, labels } from "../labels/labels";

const MyTeamTable = () => {
  const [employees, setEmployees] = useState([]);
  const [employeeDialog, setEmployeeDialog] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [fullEmployee, setFullEmployee] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [reload, setReload] = useState(false);
  const [services, setServices] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [selectedService, setSelectedService] = useState([]);
  const [loading, setLoading] = useState(true);
  const [timeSlots, setTimeSlots] = useState({
    MONDAY: [],
    TUESDAY: [],
    WEDNESDAY: [],
    THURSDAY: [],
    FRIDAY: [],
    SATURDAY: [],
    SUNDAY: [],
  });
  const propsTime = { timeSlots, setTimeSlots };
  const toast = useRef(null);
  const dt = useRef(null);
  const employeesIntegration = new EmployeesIntegration();
  const [mode, setMode] = useState(myTeam.active);
  const options = [myTeam.active, myTeam.inactive];

  //login integration
  const { user, isAuthenticated, isLoading } = useAuth0();

  //Fetch Json Data
  const loadInitialData = async () => {
    const tenantId = user.email;
    if (fullEmployee.length === 0) {
      employeesIntegration.getEmployees(tenantId).then((data) => {
        setEmployees(
          data.filter((obj) => {
            return obj.enabled === (mode === myTeam.active);
          })
        );
        setFullEmployee(data);
        setLoading(false);
      });

      //service list for combos
      employeesIntegration.getServices(tenantId).then((data) => {
        let localServices = [];
        if (data) {
          for (let i = 0, l = data.length; i < l; i += 1) {
            localServices[i] = {
              label: data[i].name,
              value: data[i].id,
            };
          }
          setServices(localServices);
        }
      });
      //user roles
      employeesIntegration.getRoles(tenantId).then((data) => {
        if (data) {
          let localRoles = [];
          for (let i = 0, l = data.length; i < l; i += 1) {
            localRoles[i] = data[i];
          }
          setRoles(localRoles);
          setReload(false);
        }
      });
    } else {
      setEmployees(
        fullEmployee.filter((obj) => {
          return obj.enabled === (mode === myTeam.active);
        })
      );
      setLoading(false);
      setReload(false);
    }
  };

  let emptyEmployee = {
    name: "",
    nickname: "",
    comercialNumber: "",
    whatsappNumber: "",
    agendaEmail: "",
    services: "",
    roles: "",
    enabled: true,
  };

  const openNew = () => {
    setEmployee(emptyEmployee);
    setEmployeeDialog(true);
  };

  const hideDialog = () => {
    setEmployeeDialog(false);
  };

  const showResponseMessage = (json) => {
    if (json.success) {
      toast.current.show({
        severity: "success",
        summary: myTeam.success,
        detail: json.message,
        life: 5000,
      });
      //trigger reload data
      setReload(true);
      setFullEmployee([]);
    } else {
      toast.current.show({
        severity: "error",
        summary: myTeam.errorTitle,
        detail: json.message,
        life: 5000,
      });
    }
    setLoading(false);
  };

  const saveEmployee = (event) => {
    setEmployeeDialog(false);
    setLoading(true);

    event.preventDefault();
    let templateEmployee = {
      ...employee,
      services: selectedService,
      roles: selectedRole,
      timeSlots: timeSlots,
    };
    const tenantId = user.email;
    employeesIntegration
      .saveEmployee(tenantId, employee, templateEmployee)
      .then((json) => {
        showResponseMessage(json);
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: myTeam.errorTitle,
          detail: myTeam.errorMsg,
          life: 5000,
        });
      });
  };

  const editEmployee = (employee) => {
    const tenantId = user.email;
    let selectedServices = [];
    employeesIntegration.getEmployee(tenantId, employee.id).then((employeeData) => {
      setEmployee(employeeData);
      setEmployeeDialog(true);

      if (employeeData.timeSlots) {
        setTimeSlots(employeeData.timeSlots);
      }

      if (employeeData.roles) {
        let selectedRoles = [];
        employeeData.roles.map((roleName) => {
          selectedRoles.push(roleName);
        });
        setSelectedRole(selectedRoles);
      }
      if (employeeData.services) {
        employeeData.services.map((service) => {
          selectedServices.push(service.id);
        });
        setSelectedService(selectedServices);
      }
    });
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _employee = { ...employee };
    _employee[`${name}`] = val;

    setEmployee(_employee);
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <SelectButton
          value={mode}
          options={options}
          onChange={(e) => {
            setMode(e.value);
            setLoading(true);
          }}
        />
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button label={myTeam.btnNew} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => editEmployee(rowData)}
        />
      </React.Fragment>
    );
  };
  const enabledTemplate = (rowData) => {
    return (
      <React.Fragment>
        <ToggleButton
          checked={rowData.enabled}
          onIcon="pi pi-check"
          offIcon="pi pi-times"
          onLabel={myTeam.active}
          offLabel={myTeam.inactive}
        />
      </React.Fragment>
    );
  };

  const header = (
    <div className="table-header">
      <h5 className="p-m-0">{myTeam.searchStaff}</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={myTeam.search} />
      </span>
    </div>
  );
  const employeeDialogFooter = (
    <React.Fragment>
      <Button label={myTeam.btnCancel} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />

      <Button label={myTeam.btnSave} icon="pi pi-check" className="p-button-text" type="submit" form="employeeForm" />
    </React.Fragment>
  );
  useEffect(() => {
    //loading logic
    if (isLoading) {
      return (
        <div>
          <br /> {labels.loadingData}
        </div>
      );
    } else if (isAuthenticated) {
      loadInitialData();
    }
  }, [isAuthenticated, reload, mode]);

  //JSX Page
  return isAuthenticated ? (
    <div className="page-container">
      <Toast ref={toast} />
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">
            <a href="#">{myTeam.addStaff}</a>
          </li>
        </ol>
      </nav>

      <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

      <DataTable
        ref={dt}
        value={employees}
        dataKey="id"
        paginator
        rows={10}
        responsiveLayout="scroll"
        rowsPerPageOptions={[5, 10, 25]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        // currentPageReportTemplate="Mostrando {first} até {last} do total {totalRecords} de Profissionais"
        globalFilter={globalFilter}
        header={header}
        resizableColumns
        columnResizeMode="fit"
        emptyMessage={myTeam.dataNotFound}
        loading={loading}
        style={{ width: "70vw" }}
      >
        <Column field="name" header={myTeam.name} sortable></Column>
        <Column field="agendaEmail" header={myTeam.email} sortable></Column>
        <Column field="whatsappNumber" header={myTeam.whatsappNumber} sortable></Column>
        <Column header={myTeam.status} style={{ width: "15em" }} body={enabledTemplate}></Column>
        <Column body={actionBodyTemplate}></Column>
      </DataTable>

      <Dialog
        visible={employeeDialog}
        style={{ width: "75vw" }}
        header="Cadastro de Profissionais"
        modal
        baseZIndex={1300}
        className="p-fluid"
        footer={employeeDialogFooter}
        onHide={hideDialog}
      >
        <form id="employeeForm" onSubmit={saveEmployee}>
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-6">
              <label htmlFor="name">{myTeam.employeeName}</label>
              <InputText
                id="name"
                value={employee.name}
                onChange={(e) => onInputChange(e, "name")}
                required
                autoFocus
              />
            </div>
            <div className="p-field p-col-12 p-md-6">
              <label htmlFor="nickname">{myTeam.nickName}</label>
              <InputText
                id="nickname"
                value={employee.nickname}
                onChange={(e) => onInputChange(e, "nickname")}
                required
              />
            </div>
            <div className="p-field p-col-12 p-md-6">
              <label htmlFor="comercialNumber">{myTeam.commercialPhone}</label>
              <InputMask
                id="comercialNumber"
                mask="(99) 99999-9999"
                value={employee.comercialNumber}
                placeholder="(DDD) 99999-9999"
                onChange={(e) => onInputChange(e, "comercialNumber")}
              ></InputMask>
            </div>
            <div className="p-field p-col-12 p-md-6">
              <label htmlFor="whatsappNumber">Whatsapp</label>
              <InputMask
                id="whatsappNumber"
                mask="(99) 99999-9999"
                value={employee.whatsappNumber}
                placeholder="(DDD) 99999-9999"
                onChange={(e) => onInputChange(e, "whatsappNumber")}
                required
              ></InputMask>
            </div>
            <div className="p-field p-col-12 p-md-6">
              <label htmlFor="agendaEmail">{myTeam.agendaEmail}</label>
              <InputText
                id="agendaEmail"
                type="email"
                value={employee.agendaEmail}
                onChange={(e) => onInputChange(e, "agendaEmail")}
                required
              />
            </div>
            <div className="p-field p-col-12 p-md-6">
              <h6 style={{ margin: "1px", marginBottom: "15px" }}>{myTeam.activeInactive}</h6>
              <InputSwitch id="enabled" checked={employee.enabled} onChange={(e) => onInputChange(e, "enabled")} />
            </div>
            <div className="p-field p-col-12 p-md-6">
              <label htmlFor="role">{myTeam.hireModel}</label>
              <MultiSelect
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.value)}
                options={roles}
                placeholder={myTeam.selectHireModel}
                display="chip"
              />
            </div>
            <div className="p-field p-col-12 p-md-6">
              <label htmlFor="service">{myTeam.serviceTypes}</label>
              <MultiSelect
                value={selectedService}
                options={services}
                onChange={(e) => setSelectedService(e.value)}
                placeholder={myTeam.selectServiceTypes}
                display="chip"
              />
            </div>
          </div>
        </form>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12 p-md-12">
            <h6>{myTeam.weekTimeTitle}</h6>
          </div>
          <div className="p-field p-col-12 p-md-12">
            <WeekTime {...propsTime} />
          </div>
        </div>
      </Dialog>
    </div>
  ) : (
    <LoginAlert />
  );
};
export default MyTeamTable;
