import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";
import staticImage from "../img/static-image.png";
import errorImage from "../img/error-image.png";
import { useAuth0 } from "@auth0/auth0-react";
import WhatsappIntegration from "../components/integration/WhatsappIntegration";

import { whatsActivation } from "../labels/labels.js";

//timer for reloading the QRCode
let timer1;

const WhatsappActivation = (props) => {
  const [targetUrl, setTargetUrl] = useState();
  const [terms1, setTerms1] = useState(false);
  const [terms2, setTerms2] = useState(false);
  const botId = props.botId;
  const whatsIntegration = new WhatsappIntegration();
  const { getAccessTokenSilently } = useAuth0();
  async function getAccessToken() {
    return await getAccessTokenSilently({
      audience: `https://lifty.us.auth0.com/api/v2/`,
      scope: "read:current_user",
    });
  }
  //recursy call each 3s
  const showQrCode = (statusURL, baseQRCodeURL) => {
    //create unique URL to avoid cache
    let qrCodeURL = baseQRCodeURL + "&time=" + new Date().getTime();
    whatsIntegration.getSessionStatus(statusURL).then((response) => {
      if (response === "CONNECTED") {
        if (timer1) {
          clearTimeout(timer1);
        }
        props.setConnected(true);
        props.setShowPlay(false);
        props.setShowPause(true);
        props.handleCloseWhatsapp();
      } else if (response === "QRCODE") {
        setTargetUrl(qrCodeURL);
      } else if (response === "ERROR") {
        //mostrar uma imagem com error, tente novamente
        setTargetUrl(errorImage);
        //stop timer
        if (timer1) {
          clearTimeout(timer1);
        }
      } else {
        setTargetUrl(staticImage);
      }
    });
    timer1 = setTimeout(() => showQrCode(statusURL, baseQRCodeURL), 3000);
  };

  //start new whatsapp connection session
  const startWhatsappSession = () => {
    setTargetUrl(staticImage);
    getAccessToken().then((accessToken) => {
      whatsIntegration
        .startSession(botId, accessToken)
        .then((data) => {
          console.log("QRCode URL", data.tokenURL);
          timer1 = setTimeout(() => showQrCode(data.statusURL, data.tokenURL), 2000);
        })
        .catch((error) => {
          setTargetUrl(errorImage);
        });
    });
  };

  return (
    <div>
      <Typography variant="h4" component="h4" style={{ marginBottom: "20px" }}>
        {whatsActivation.pageTitle}
      </Typography>
      <Typography variant="body1" component="div">
        {whatsActivation.openWhats}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {whatsActivation.node} <br />
      </Typography>

      <div style={{ color: "black", fontSize: "14px", margin: "0" }}>
        <Checkbox checked={terms1} onChange={(e) => setTerms1(e.target.checked)} />
        <Typography variant="body2" style={{ display: "inline-block" }}>
          {whatsActivation.terms1}
        </Typography>
      </div>
      <div style={{ color: "black", fontSize: "14px", margin: "0" }}>
        <Checkbox checked={terms2} onChange={(e) => setTerms2(e.target.checked)} />
        <Typography variant="body2" style={{ display: "inline-block" }}>
          {whatsActivation.terms2}&nbsp;
          <a href="https://www.whatsapp.com/legal/business-policy/?lang=pt_br" target="_blank">
            {whatsActivation.pageTitle}
          </a>
        </Typography>
      </div>
      <div>
        <Button
          size="small"
          color="primary"
          variant="contained"
          disabled={!terms1 || !terms2}
          onClick={startWhatsappSession}
        >
          {whatsActivation.showQr}
        </Button>
      </div>
      <div className="qrcode" style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
        {targetUrl && <img src={targetUrl} alt="QR CODE" height="264" width="264" />}
      </div>
    </div>
  );
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default WhatsappActivation;
