export default class VotingIntegration {
  sendVotingEmail(botId, integrationName, user) {
    const votingEmail = {
      contacts: ["meubot@lifty.com.br"],
      subject: "Integração desejada por cliente",
      content: `Cliente: ${user.email}, quer a integação: ${integrationName}`,
    };
    return fetch(`${process.env.REACT_APP_AGENDA_API_URL}/email/${botId}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(votingEmail),
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
}
