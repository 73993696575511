import React, { useEffect, useState, useRef } from "react";

// ############## MATERIAL UI IMPORTS ######################

import Grid from "@mui/material/Grid";
import { Link, useLocation, useNavigate } from "react-router-dom";

// ############## PRIME REACT IMPORTS ######################

import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";

import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { CreateBotIntegration } from "../components/integration/CreateBotIntegration";
import { useAuth0 } from "@auth0/auth0-react";
import LoginAlert from "./LoginAlert";
import { labels, createBotClient } from "../labels/labels.js";

//Get URL Params
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function CreateBot() {
  const toast = useRef(null);

  const createBotIntegration = new CreateBotIntegration();

  const [botNameDialog, setBotNameDialog] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [templateId, setTemplateId] = useState();
  const [botId, setBotId] = useState("");
  const [botName, setBotName] = useState("");
  const [progress, setProgress] = useState(false);
  const [sucessDialog, setSucessDialog] = useState(false);
  const navigate = useNavigate();

  const [instanceId, setInstanceId] = useState("");

  const { user, isAuthenticated } = useAuth0();

  //Get BusinessLine
  const query = useQuery();
  let BusinessLine = query.get("businessLine"); //Where BotId is the paramName on the URl.

  const showResponseMessage = (json) => {
    setBotId(json.id);
    setBotName(json.botName);
    if (json.status && json.status !== 200) {
      toast.current.show({
        severity: "error",
        summary: "Erro!",
        detail: json.error,
        life: 5000,
      });
    } else {
      toast.current.show({
        severity: "success",
        summary: createBotClient.success,
        detail: createBotClient.botSuccess,
        life: 5000,
      });
    }
  };

  useEffect(() => {
    if (BusinessLine === "Personalizado") {
      const tenantId = user.email;
      createBotIntegration
        .getTemplatesByCreatedBy(tenantId)
        .then((data) => {
          if (data) {
            setTemplates(data);
          } else {
            setTemplates("");
          }
        })
        .catch((error) => {
          console.log(error);
          toast.current.show({
            severity: "error",
            summary: createBotClient.errorTitle,
            detail: createBotClient.errorMessage,
            life: 5000,
          });
        });
    } else {
      createBotIntegration
        .getTemplates()
        .then((data) => {
          if (data) {
            setTemplates(data);
          } else {
            setTemplates("");
          }
        })
        .catch((error) => {
          console.log(error);
          toast.current.show({
            severity: "error",
            summary: createBotClient.errorTitle,
            detail: createBotClient.errorMessage,
            life: 5000,
          });
        });
    }
  }, []);
  const onHide = (field) => {
    setSucessDialog(false);
    setBotNameDialog(false);
  };
  const configureBot = (botId) => {
    createBotIntegration
      .configureBot(botId)
      .then((json) => {
        toast.current.show({
          severity: "success",
          summary: createBotClient.success,
          detail: createBotClient.botCustomSuccess,
          life: 5000,
        });
        setProgress(false);
        setBotName("");
      })
      .catch((error) => {
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: createBotClient.errorTitle,
          detail: createBotClient.errorMessage,
          life: 5000,
        });
        setProgress(false);
        setBotName("");
      });
  };

  const handleSubmit = () => {
    const tenant = user.email;
    // eslint-disable-next-line no-console
    let templateBot = {
      botName: botName,
      templateId: templateId,
      instanceId: instanceId,
      tenantId: tenant,
    };

    createBotIntegration
      .saveBot(templateBot)
      .then((json) => {
        showResponseMessage(json);
        if (json.id) {
          setBotId(json.id);
          setBotName(json.botName);
          setProgress(true);
          setSucessDialog(true);
          configureBot(json.id);
          setBotNameDialog(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: createBotClient.errorTitle,
            detail: createBotClient.errorMessage,
            life: 5000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: "Erro!",
          detail: "Erro ao salvar o Bot.",
          life: 5000,
        });
      });
  };
  const botNameDialogFooter = (
    <React.Fragment>
      <Button label={createBotClient.cancel} icon="pi pi-times" className="p-button-text" onClick={() => onHide()} />

      <Button label={createBotClient.createBot} icon="pi pi-check" className="p-button-text" onClick={handleSubmit} />
    </React.Fragment>
  );

  const handleChangeTemplate = (event) => {
    let template = event.id;
    setTemplateId(event.id);
    createBotIntegration
      .getInstance(template)
      .then((data) => {
        if (data) {
          let highestAvailableInstanceId;
          for (let i = 0; i < data.length; i++) {
            if (data[i].currentAvailableBotSlots > 0) {
              highestAvailableInstanceId = data[i].id;
              break;
            }
          }
          if (highestAvailableInstanceId) {
            setInstanceId(highestAvailableInstanceId);
            setBotNameDialog(true);
          } else {
            window.alert(createBotClient.botMaxAlert);
          }
        } else {
          setInstanceId("");
          setBotNameDialog(true);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: createBotClient.errorTitle,
          detail: createBotClient.errorMessage,
          life: 5000,
        });
      });
  };
  function Content() {
    return templates.map((template) => {
      if (template.businessLine === BusinessLine) {
        return (
          <div class="pricing-plan">
            <h2 class="pricing-header" style={{ minHeight: "45px" }}>
              {template.nameTemplate}
            </h2>
            <ul class="pricing-features">
              <li class="pricing-features-item">{template.businessLine}</li>
              <li class="pricing-features-item">{template.templateDescription}</li>
              <li class="pricing-features-item">{template.templateLanguage}</li>
            </ul>
            <ul class="pricing-features">
              <h3 class="pricing-header">{createBotClient.features}</h3>
              {template.templateFeatures.map((feature) => {
                return <li class="pricing-features-item">{feature} </li>;
              })}
            </ul>
            <Button
              className="p-button-lg p-button-success"
              icon="pi pi-android"
              label={createBotClient.createBot}
              onClick={() => handleChangeTemplate(template)}
            />
          </div>
        );
      }
      return null;
    });
  }

  return isAuthenticated ? (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/MyBots">{labels.breadCrumbs}</Link>
          </li>
          <li className="breadcrumb-item active">
            <a href="#">{createBotClient.createBot}</a>
          </li>
        </ol>
      </nav>
      <Toast ref={toast} />
      {templates.length > 0 ? (
        <div>
          <div class="panel h5-bot">
            <h5 style={{ marginTop: "5px" }}>{createBotClient.chooseTemplate}</h5>
          </div>
          <div class="container-bot">
            <div class="panel pricing-table">
              <Content />
            </div>
          </div>
        </div>
      ) : (
        <div class="panel h5-bot">
          <h5 style={{ marginTop: "5px" }}>{createBotClient.emptyTemplate}</h5>
        </div>
      )}

      <Dialog
        visible={sucessDialog}
        style={{ width: "800px" }}
        header={createBotClient.botSuccess}
        modal
        className="p-d-flex"
        onHide={() => onHide()}
        baseZIndex="1300"
      >
        {progress ? (
          <div className="p-grid">
            <div className="p-col-5 p-d-flex p-ai-center">
              <h5>{createBotClient.waitingMsg}</h5>
            </div>
            <div className="p-col-3 p-d-flex p-ai-center">
              <ProgressSpinner />
            </div>
            <div className="p-col-4">
              <img width="250px" src="/BOTTY_NOBG.png" />
            </div>
          </div>
        ) : (
          <div className="p-grid">
            <div className="p-col-4">
              <img width="250px" src="/BOTTY_NOBG.png" />
            </div>
            <div className="p-col-8">
              <div className="p-grid">
                <div className="p-col-12">
                  <h5>{createBotClient.botSuccess}</h5>
                </div>
                <div className="p-col-12">
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => window.open(process.env.REACT_APP_CHAT_URL + "/chat?botId=" + botId, "_blank")}
                  >
                    {createBotClient.testMsg}
                  </Button>
                </div>
                <div className="p-col-12">
                  <Button
                    variant="contained"
                    color="info"
                    onClick={() => navigate("/Integrations?botId=" + botId + "&botName=" + botName)}
                  >
                    {createBotClient.integrationMsg}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Dialog>
      <Dialog
        visible={botNameDialog}
        modal
        baseZIndex={1450}
        onHide={() => onHide()}
        footer={botNameDialogFooter}
        header={createBotClient.chooseName}
        style={{ width: "30vw" }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <span className="p-float-label">
              <InputText
                style={{ display: "flex", width: "100%", marginTop: "20px" }}
                onChange={(e) => setBotName(e.target.value)}
                autoResize
              />
              <label htmlFor="title">{createBotClient.nameBot}</label>
            </span>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  ) : (
    <LoginAlert />
  );
}
