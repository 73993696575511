import { makeStyles } from '@material-ui/core/styles';

// A custom theme for this app
const useGlobalStyles = makeStyles((theme) => ({
  root: {
    minWidth: "100%",
    minHeight: "80vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  card: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    maxWidth: "95%",
    minWidth: "95%",
    minHeight: "100vh",
    alignItems: "center"
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  paper: {
    maxWidth: "100%",

    margin: theme.spacing(1),
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(3),
    }
  },
  paper1: {
    maxWidth: "100%",

    margin: theme.spacing(0),
    padding: theme.spacing(0.5),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      padding: theme.spacing(1),
    }
  },
  input: {
    width: 42,
  },
  slider: {
    width: 300,
  }
}
));

export default useGlobalStyles
