export default class ParamsIntegration {

    getBotConfiguration(botId) {
        return fetch(`${process.env.REACT_APP_BOT_API}/bot/${botId}`)
            .then(res => res.json())
            .catch(error => {
                console.log(error);
                window.alert('Erro ao obter lista de parametros do bot.');
            });
    }

    saveBotConfiguration(botId, fullBotBody) {
        return fetch(`${process.env.REACT_APP_BOT_API}/bot/${botId}`, {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(fullBotBody),
        })
            .then(response => response.json())
            .catch(error => {
                throw error;
            });
    }
}