export default class WhatsappIntegration {

  getWhatsappStatusURL(botId, accessToken) {
    const getStatusURL = `${process.env.REACT_APP_DASHBOARD_GATEWAY}/integration/bot/${botId}/whatsapp`;

    return fetch(getStatusURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        mode: 'no-cors'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (data.statusURL) {
            return data.statusURL;
          } else {
            console.log('Status URL is not present in response');
            return null;
          }
        }
      }).catch((error) => {
        return 'ERROR';
      });
  }

  getSessionStatus(statusURL) {
    return fetch(statusURL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Result for whatsapp status request.', data);
        if (data) {
          if (data.result) {
            return data.result;
          } else {
            return 'UNKNOW';
          }
        }
      }).catch((error) => {
        return 'ERROR';
      });
  }

  startSession(botId, accessToken) {
    const whatsappURL = `${process.env.REACT_APP_DASHBOARD_GATEWAY}/integration/bot/${botId}/startWhatsapp?options=c`;

    return fetch(whatsappURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        mode: 'no-cors'
      }
    })
      .then((response) => response.json())
      .catch((error) => {
        console.log("Erro ao tentar iniciar integração com whatsapp.", error);
        throw error;
      });
  }

  stopListener(botId, accessToken) {
    const whatsappURL = `${process.env.REACT_APP_DASHBOARD_GATEWAY}/integration/bot/${botId}/stopWhatsapp`;

    return fetch(whatsappURL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        mode: 'no-cors'
      }
    })
      .then((response) => response.json())
      .catch((error) => {
        console.log("Erro ao tentar parar integração com whatsapp.", error);
        throw error;
      });
  }
}