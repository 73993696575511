export default class ModelIntegration {
  getNodes(botId) {
    return fetch(`${process.env.REACT_APP_BOT_API}/model/${botId}/`)
      .then((res) => res.json())
      .then((data) => data.root)
      .catch((error) => {
        console.log(error);
        window.alert("Erro ao obter lista de Menu & Produtos.");
      });
  }
  getEmployees(botId) {
    return fetch(`${process.env.REACT_APP_BOT_API}/data/tenant/${botId}/employee`)
      .then((res) => res.json())
      .then((d) => d)
      .catch((error) => {
        console.log(error);
      });
  }
  getServices(botId) {
    return fetch(`${process.env.REACT_APP_BOT_API}/data/tenant/${botId}/service/`)
      .then((res) => res.json())
      .then((d) => d)
      .catch((error) => {
        console.log(error);
      });
  }

  saveModel(nodes, bodySave, botId) {
    return fetch(`${process.env.REACT_APP_BOT_API}/model/${botId}/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodySave),
    })
      .then((response) => {
        if (response.status === 200) {
          response.json();
        } else {
          // eslint-disable-next-line no-throw-literal
          throw {
            status: response.status,
            success: false,
            error: "Error saving models",
            details: response.statusText
          };
        }
      })
      .catch((error) => {
        throw error;
      });
  }
}
