// --- Imports --- //
import React from "react";

// --- Material Ui Imports --- //
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import { labels } from "../labels/labels";

import { useAuth0 } from "@auth0/auth0-react";

// --- Style --- //
const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    width: 600,
    position: "relative",
  },
  media: {
    height: 140,
  },

  cardContentTextSecondary: {
    color: "rgba(255,255,255,0.78)",
  },
  cardActionArea: {
    position: "relative",
  },
  cardMedia: {
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
    width: "100%",
  },
  cardContent: {
    position: "relative",
    color: "#ffffff",
    backgroundColor: "rgba(0,0,0,.24)",
  },
  cardActions: {
    position: "relative",
  },
});

const LoginAlert = () => {
  const { loginWithRedirect } = useAuth0();

  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <Box my={4}>
        <Card className={classes.card}>
          <CardActionArea className={classes.cardActionArea}>
            <CardMedia
              media="picture"
              alt="Contemplative Reptile"
              image="/loginAlertImagem.jpg"
              title="Contemplative Reptile"
              className={classes.cardMedia}
            />
            <CardContent className={classes.cardContent}>
              <Typography gutterBottom variant="h5" component="h2">
                {labels.notLogged1}
              </Typography>
              <Typography
                variant="body2"
                className={classes.cardContentTextSecondary}
                component="p"
              >
                {labels.notLogged2}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button
              size="large"
              color="primary"
              variant="outlined"
              fullWidth
              onClick={() => loginWithRedirect({})}
            >
              {labels.loginButton}
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Container>
  );
};

export default LoginAlert;
