export default class EventsCreationIntegration {

    createEvent(templateEvent, tenantId, botId, date, serviceId) {
        let json = JSON.stringify(templateEvent);
        return fetch(process.env.REACT_APP_AGENDA_API_URL + '/meetings/schedule/' + tenantId + '/bot/' + botId + '/' + date + '/' + serviceId, 
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: json,
            mode: 'no-cors'
        })
            .then(response => response.json())
            .catch(error => {
                throw error;
            });
    }
}