import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Typography, Box, Menu, MenuItem, IconButton, Avatar, Container } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth0 } from "@auth0/auth0-react";
import { topNavBar } from "../../labels/labels";

const useStyles = makeStyles({
  appbar: {
    width: `calc(100% - 350px)`,
    ml: 350,
    background: "#000c1e",
    height: 65,
  },
});

function TopNavBar() {
  //MUI Dropdown Menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isOpen, setIsOpen] = useState(false);
  const { user, isAuthenticated, logout } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate();

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });
  const profileWithRedirect = () => {
    navigate("/profile");
  };

  const classes = useStyles();
  return (
    <AppBar
      position="fixed"
      className={classes.appbar}
      elevation={0}
      sx={{
        left: {
          lg: 350,
        },
        width: {
          lg: "calc(100% - 350px)",
          background: "#000c1e",
        },
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {isAuthenticated && (
            <>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1, mr: 2, display: { xs: "none", md: "flex" } }}
              >
                {user.name}, {topNavBar.welcome}
              </Typography>
              <Box sx={{ flexGrow: 0 }}>
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar sx={{ width: 50, height: 50 }}>
                    <img src={user.picture} alt="Profile" className="nav-user-profile rounded-circle" width="50" />
                  </Avatar>
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem onClick={() => logoutWithRedirect()}>{topNavBar.logout}</MenuItem>
                  <MenuItem onClick={() => profileWithRedirect()}>{topNavBar.profile}</MenuItem>
                </Menu>
              </Box>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default TopNavBar;
