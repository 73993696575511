import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import BotReportIntegration from "../components/integration/BotReportIntegration";
import Title from "./Title";
import { blue, green, pink } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import { homePage, labels } from "../labels/labels.js";

const useStyles = makeStyles((theme) => ({
  depositContext: {
    flex: 1,
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
  blue: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
  },
  green: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: green[500],
  },
}));

function BotReportStatus() {
  const botReportIntegration = new BotReportIntegration();
  const [shouldLoadData, setShouldLoadData] = useState(true);
  const [botStatus, setBotStatus] = useState([]);
  const classes = useStyles();

  const { user, isAuthenticated, isLoading } = useAuth0();

  // Load Json Data
  useEffect(() => {
    if (shouldLoadData) {
      const tenantId = user.email;
      botReportIntegration.getBotStatus(tenantId).then((data) => {
        if (data) {
          setBotStatus(data);
          setShouldLoadData(false);
        }
      });
    }
  });

  return (
    <React.Fragment>
      <Title>{labels.botInteractions}</Title>
      <CardActionArea>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.green}>
              {botStatus.totalSessions}
            </Avatar>
          }
          title={homePage.report.numberCustomers}
          subheader={homePage.report.totalNumberCustomers}
        />
      </CardActionArea>
      <CardActionArea>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.blue}>
              {botStatus.totalMessagesReceived}
            </Avatar>
          }
          title={homePage.report.msgReceived}
          subheader={homePage.report.totalNumberReceived}
        />
      </CardActionArea>
      <CardActionArea>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.pink}>
              {botStatus.totalMessagesSent}
            </Avatar>
          }
          title={homePage.report.msgSente}
          subheader={homePage.report.totalNumberSent}
        />
      </CardActionArea>
    </React.Fragment>
  );
}

export default BotReportStatus;
