export default class RemindersIntegration {

    getConfig(botId) {
        return fetch(process.env.REACT_APP_AGENDA_API_URL + '/confirmation/' + botId).then(response => {
            return response.json();
        })
            .catch(error => {
                throw error;
            });
    }

    putConfig(templateConfig) {
        let json = JSON.stringify(templateConfig);
        return fetch(process.env.REACT_APP_AGENDA_API_URL + '/confirmation/' + templateConfig.botId, {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: json,
        })
            .then(response => {
                if (response.status === 200) {
                    return {
                        success: true
                    };
                } else {
                    return {
                        success: false
                    };
                }
            })
            .catch(error => {
                throw error;
            });
    }
}