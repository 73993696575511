export default class UserDataIntegration {

    listUserData(botId) {
        return fetch(process.env.REACT_APP_BOT_API + '/bot/' + botId + '/userdata').then(response => {
            return response.json();
        })
            .catch(error => {
                throw error;
            });
    }

    PutUserData(botId, templateUserData) {
        let json = JSON.stringify(templateUserData);
        return fetch(process.env.REACT_APP_BOT_API + '/bot/' + botId + '/userdata', {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: json,
        })
            .then(response => {
                if (response.status === 200) {
                    return {
                        success: true
                    };
                } else {
                    return {
                        success: false
                    };
                }
            })
            .catch(error => {
                throw error;
            });
    }
    listUserDataTypes(templateId) {
        return fetch(process.env.REACT_APP_BOT_API + '/bot-templates/' + templateId + '/userdatatypes', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(res => res.json())
            .then(d => d);
    }
}
