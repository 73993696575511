import React, { useState } from "react";

import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";

import useGlobalStyles from "./css/GlobalStyles";

import Paper from "@mui/material/Paper";
import { weektimes } from "../labels/labels.js";

import "primeflex/primeflex.css";

//Main Function
const WeekTime = ({ timeSlots, setTimeSlots }) => {
  const classes = useGlobalStyles();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [dayOfWeek, setDayOfWeek] = useState("MONDAY");
  const [activeIndex, setActiveIndex] = useState(0);

  const WEEK_DAYS = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];

  function updateUI(dayOfWeek) {
    //day of the week can not be undefined
    if (dayOfWeek && !timeSlots[dayOfWeek]) {
      timeSlots[dayOfWeek] = [];
    }
    var newJson = JSON.parse(JSON.stringify(timeSlots));
    setTimeSlots(newJson);
  }
  function validateTimes(newStartTime, newEndTime) {
    if (newStartTime && newStartTime < "23:59:59" && newEndTime && newEndTime < "23:59:59") {
      if (newEndTime > newStartTime) {
        return true;
      } else {
        window.alert("Horário final deve ser maior que horario inicial!");
        return false;
      }
    } else {
      window.alert("Horário inválido!");
      return false;
    }
  }

  function addNewSlot() {
    let newTimeOptions = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    let newStartTime = startTime?.toLocaleString("pt-BR", newTimeOptions);
    let newEndTime = endTime?.toLocaleString("pt-BR", newTimeOptions);
    if (validateTimes(newStartTime, newEndTime)) {
      timeSlots[dayOfWeek].push({
        startTime: newStartTime,
        endTime: newEndTime,
      });
    }
    updateUI();
  }

  function getCurrentMap() {
    if (dayOfWeek && !timeSlots[dayOfWeek]) {
      timeSlots[dayOfWeek] = [];
    }
    return timeSlots[dayOfWeek];
  }

  function removeTimeSlot(e, index) {
    const filteredItems = timeSlots[dayOfWeek]
      .slice(0, index)
      .concat(timeSlots[dayOfWeek].slice(index + 1, timeSlots[dayOfWeek].length));
    timeSlots[dayOfWeek] = filteredItems;
    updateUI();
  }

  function setActiveTabs(e) {
    setActiveIndex(e.index);
    setDayOfWeek(WEEK_DAYS[e.index]);
    updateUI(WEEK_DAYS[e.index]);
  }

  return (
    <Paper className={classes.paper} elevation={2}>
      <div>
        <div className="p-fluid p-grid">
          <div className="p-field p-col-12 p-md-12">
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveTabs(e)}>
              <TabPanel header={weektimes.weekDay1}></TabPanel>
              <TabPanel header={weektimes.weekDay2}></TabPanel>
              <TabPanel header={weektimes.weekDay3}></TabPanel>
              <TabPanel header={weektimes.weekDay4}></TabPanel>
              <TabPanel header={weektimes.weekDay5}></TabPanel>
              <TabPanel header={weektimes.weekDay6}></TabPanel>
              <TabPanel header={weektimes.weekDay7}></TabPanel>
            </TabView>
          </div>

          <div className="p-field p-col-12 p-md-5">
            <span className="p-float-label">
              <Calendar
                mask="99:99"
                showIcon
                timeOnly
                showTime
                hourFormat="24"
                value={startTime}
                onChange={(e) => setStartTime(e.value)}
              ></Calendar>
              <label htmlFor="time">{weektimes.startTime}*</label>
            </span>
          </div>
          <div className="p-field p-col-12 p-md-5">
            <span className="p-float-label">
              <Calendar
                mask="99:99"
                showIcon
                timeOnly
                showTime
                hourFormat="24"
                value={endTime}
                onChange={(e) => setEndTime(e.value)}
              ></Calendar>
              <label htmlFor="time">{weektimes.endTime}*</label>
            </span>
          </div>
          <div className="p-field p-col-12 p-md-2">
            <Button icon="pi pi-plus" className="p-button-rounded p-button-success" onClick={() => addNewSlot()} />
          </div>

          <div className="p-field p-col-12 p-md-12">
            <table className="pure-table p-field p-col-12 p-md-12" style={{ textAlign: "center" }}>
              <thead style={{ textAlign: "center" }}>
                <tr>
                  <th>{weektimes.startTime}</th>
                  <th>{weektimes.endTime}</th>
                  <th></th>
                </tr>
              </thead>
              {getCurrentMap().map((data, index) => (
                <tbody>
                  <tr>
                    <td>{data.startTime}</td>
                    <td>{data.endTime}</td>
                    <td>
                      <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-danger"
                        onClick={(e) => removeTimeSlot(e, index)}
                      />
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        </div>
      </div>
    </Paper>
  );
};
export default WeekTime;
