import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { makeStyles } from "@material-ui/core/styles";
import { myProfile } from "../labels/labels.js";

//Table Styles
const useStyles = makeStyles((theme) => ({
  inline: {
    fontWeight: 600,
    fontSize: 20,
  },
}));

const UserProfile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  //styles (Classes)
  const classes = useStyles();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    isAuthenticated && (
      <div className={classes.inline}>
        {myProfile.logged} &nbsp;{user.email}
      </div>
    )
  );
};

export default UserProfile;
