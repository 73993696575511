import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";

// ############## MATERIAL UI IMPORTS ######################

import { useNavigate } from "react-router-dom";
import { CreateBotIntegration } from "../components/integration/CreateBotIntegration";

// ############## PRIME REACT IMPORTS ######################

import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";

import { useAuth0 } from "@auth0/auth0-react";
import LoginAlert from "./LoginAlert";

export default function BusinessLine() {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [businessLine, setBusinessLine] = useState([]);
  const createBotIntegration = new CreateBotIntegration();

  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    createBotIntegration
      .getBusiness()
      .then((data) => {
        if (data) {
          setBusinessLine(data);
        } else {
          setBusinessLine("");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: "Erro!",
          detail: "Erro ao listar businessLine",
          life: 5000,
        });
      });
  }, []);
  const selectionBusiness = (props) => {
    navigate("/CriarMeuBot?businessLine=" + props.title);
  };
  const header = (lines) => {
    return <img alt={lines.imageURL} src={lines.imageURL} width={300} height={300} />;
  };
  const footer = (lines) => {
    return (
      <span>
        <Button label="Selecionar" icon="pi pi-check" onClick={() => selectionBusiness(lines)} />
      </span>
    );
  };

  function Content() {
    return businessLine.map((lines) => {
      if (lines.enabled) {
        return (
          <Grid item key={lines.id} xs={6}>
            <Card title={lines.name} subTitle={lines.subtitle} footer={footer} header={header(lines)}>
              <p className="p-m-0" style={{ lineHeight: "1.5" }}>
                {lines.description}
              </p>
            </Card>
          </Grid>
        );
      }
      return null;
    });
  }

  return isAuthenticated ? (
    <div>
      <Toast ref={toast} />
      <div class="panel business-table">
        <Grid container spacing={2}>
          <Content />
        </Grid>
      </div>
    </div>
  ) : (
    <LoginAlert />
  );
}
